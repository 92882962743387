import { Button, Form, Modal, Stack } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { SlashCircle } from "react-bootstrap-icons";
import "./TableData.css";
import { useState, useEffect } from "react";

import moment from "moment";
import ScheduleModal from "./ScheduleModal";
import { useDispatch, useSelector } from "react-redux";
import {
  TurnOffAllApi,
  TurnOffApi,
  TurnOnAllApi,
  TurnOnApi,
  deviceBlock,
  houseDataEmpty,
} from "../Redux/Action/PropertyAction";
import axios from "axios";
import FetchScheduleRow from "./FetchScheduleRow";
import DeviceOnLIneOffLine from "./DeviceOnLIneOffLine";
import LogModalOnTime from "./LogModalOnTime";
import { house } from "../Redux/Action/ActionTypes";
import ConfirmationModel from "./ConfirmationModel";
import AutoCutApi from "./AutoCutApi";
import { notifyFailed } from "../utlity/Notification";
import EditHouseDetails from "./EditHouseDetails";
import AutoCutMap from "./AutoCutMap";
import { CSVLink } from "react-csv";
import UpdateWifi from "./UpdateWifi";
import { getAnalysisData, getSchedule } from "../utlity/apis";
import NewScheduleModal from "./newSchedule/NewScheduleModal";
import NewFetchScheduleRow from "./newSchedule/NewFetchScheduleRow";
import { convertMillisecondsToString, delay } from "../utlity/utils";
import MacIds from "./reports/macIds";
import SwitchLiveStatus from "./SwitchLiveStatus";
import { useNavigate } from "react-router-dom";
import { addSchedule } from "../Redux/Action/NewSchedule";

function TableData({
  citys,
  selectedCity,
  selectedAppliance,
  selectedRoom,
  selectedProperty,
  city,
  newSchedule,
  connectionStatus,
  switchStatus
}) {
  let HouseDetails = useSelector((state) =>
    state.HouseDetails.filter((h) => {
      if (selectedCity === "Delhi-NCR") {
        const citiesToShow = ["Delhi", "Gurgaon", "Noida"];
        if (citiesToShow.includes(h.city)) {
          return h;
        }
      }
      if (selectedCity !== "ALL") {
        if (h.city === selectedCity) {
          return h;
        }
      } else if (selectedCity === "ALL") {
        return h;
      }
    })
  );
  const navigate = useNavigate();
  let PropertyDetails = useSelector((state) => state.PropertyDetails);
  let User = useSelector((state) => state.User);
  const schedule = useSelector((state) => state.schedule);
  let [logSwitch, setLogSwitch] = useState({});
  let [productDataItem, setProductDataItem] = useState([]);
  let dispatch = useDispatch(null);
  const [csvData, setCsvData] = useState({});

  // Schedule
  const [modalShow, setModalShow] = useState(false);
  const [newModalShow, setNewModalShow] = useState(false);

  const [logModalShow, setLogModalShow] = useState(false);
  const [csvTimeSelectorModal, setCsvTimeSelectorModal] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const maxDate = moment(
    new Date(new Date().setDate(new Date().getDate() - 1))
  ).format("YYYY-MM-DD");
  const productDataFilterAdd = (value, checked) => {
    if (checked === true) {
      setProductDataItem([...productDataItem, value]);
    } else if (checked === false) {
      let newData = productDataItem.filter((e) => {
        if (e._id !== value._id) {
          return e;
        }
      });
      setProductDataItem(newData);
    }
  };

  useEffect(() => {
    if (productDataItem.length == 0) {
      let checkBox = document.getElementsByName("chk");

      for (var i = 0; i < checkBox.length; i++) {
        if (checkBox[i].type == "checkbox") checkBox[i].checked = false;
      }
      let checkBoxAll = document.getElementById("AllChk");
      checkBoxAll.checked = false;
    }
  }, [productDataItem]);

  useEffect(() => {
    setProductDataItem([]);
    let checkBoxAll = document.getElementById("AllChk");
    checkBoxAll.checked = false;
  }, [selectedProperty]);

  

  useState(() => {}, [schedule]);
  const selectAllBox = (checked) => {
    let checkBox = document.getElementsByName("chk");
    for (var i = 0; i < checkBox.length; i++) {
      if (checkBox[i].type == "checkbox") checkBox[i].checked = checked;
    }
    if (checked === true) {
      let switches = [];
      for (let house of HouseDetails) {
        
        for (let room of house.rooms) {
          for (let device of room.devices) {
            for (let swit of device.switches) {
              if (
                selectedAppliance !== "" &&
                swit.switchName.includes(selectedAppliance) &&
                house.propertyName.includes(selectedProperty)
              ) {
                let obj = {
                  ...swit,
                  deviceId: device.deviceId,
                  mqt: device.isMQTTDevice,
                  houseName: house.propertyName,
                  roomName: room.roomName,
                  roomId: room._id,
                  deviceType: swit.deviceType,
                  type:
                    device.deviceId.substring(1, 3) == "08" ? "nova" : "sync",
                  houseId: house.house._id,
                };
                switches.push(obj);
              } else if (
                selectedAppliance === "" &&
                house.propertyName.includes(selectedProperty)
              ) {
                let obj = {
                  ...swit,
                  deviceId: device.deviceId,
                  mqt: device.isMQTTDevice,
                  houseName: house.propertyName,
                  roomName: room.roomName,
                  roomId: room._id,
                  deviceType: swit.deviceType,
                  type:
                    device.deviceId.substring(1, 3) == "08" ? "nova" : "sync",
                  houseId: house.house._id,
                };
                switches.push(obj);
              }
            }
          }
        }
      }

      setProductDataItem(switches);
    } else {
      setProductDataItem([]);
    }
  };

  const todayLogOntime = async (deviceId, switchId) => {
    return await axios
      .post(
        `https://analytics.alistetechnologies.com:443/analytic/TodayLogsByDateDevice`,
        {
          deviceId: deviceId,
          roomId: "DFS",
        }
      )
      .then(function (res) {
        if (res.data.final.length !== 0) {
          let filerSwitch = res.data.final.find((d) => {
            if (d.deviceId === deviceId && d.switchId === switchId) {
              return d;
            }
          });
          const durationInMilliseconds = filerSwitch.ontime;

          const seconds = Math.floor(durationInMilliseconds / 1000);
          const hours = Math.floor(seconds / 3600);
          const minutes = Math.floor((seconds % 3600) / 60);
          const remainingSeconds = seconds % 60;

          const formattedTime = `${hours}:${minutes
            .toString()
            .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
          return formattedTime;
        } else {
          return "0";
        }
      })
      .catch(function (err) {
        return "0";
      });
  };

  const calculate = (date) => {
    let milliseconds = date;

    const seconds = Math.floor((milliseconds / 1000) % 60);

    const minutes = Math.floor((milliseconds / 1000 / 60) % 60);

    const hours = Math.floor((milliseconds / 1000 / 60 / 60) % 24);

    const formattedTime = [
      hours.toString().padStart(2, "0"),
      minutes.toString().padStart(2, "0"),
      seconds.toString().padStart(2, "0"),
    ];

    return `${hours.toString().padStart(2, "0")}h ${minutes
      .toString()
      .padStart(2, "0")}m `;
  };

  async function generateCsv() {
    const obj = [];
    const summaryReport = [];
    const stateKeyName = `${selectedCity}_${startDate}_${endDate}`;
    setCsvData((state) => ({
      ...state,
      [stateKeyName]: {
        data: [],
        summary: [],
        state: "Processing...",
      },
    }));

    if (productDataItem.length === 0) {
      notifyFailed("No Selected Property!!");
      return;
    }

    for (const house of productDataItem) {
      const dates = getDatesInRange(startDate, endDate);

      for (const d of dates) {
        let resp = await getAnalysisData(house.deviceId, d);

        if (resp.data.company === "success") {
          for (let data of resp.data.final) {
            summaryReport.push({
              Property: house.houseName,
              Area: house.roomName,
              DeviceId: house.deviceId,
              Name: house.switchName,
              Date: moment(d).format("DD-MMM-YYYY"),
              "On Time": convertMillisecondsToString(data.ontime),
              "OnLine Time": data?.onlineTime
                ? convertMillisecondsToString(data.onlineTime)
                : 0,
            });
            
            if (!data.sessions || data.sessions.length === 0) {
              obj.push({
                Property: house.houseName,
                Area: house.roomName,
                DeviceId: house.deviceId,
                Name: house.switchName,
                StartTime: "-",
                EndTime: "-",
                "Total Session": "-",
                Date: moment(d).format("DD-MMM-YYYY"),
                "On Time": convertMillisecondsToString(data.ontime),
                "Online Time": data?.onlineTime
                  ? convertMillisecondsToString(data.onlineTime)
                  : 0,
              });

              continue;
            }

            for (const session of data.sessions) {
              obj.push({
                Property: house.houseName,
                Area: house.roomName,
                DeviceId: house.deviceId,
                Name: house.switchName,
                StartTime: moment(session.startTime).format("LT"),
                EndTime: moment(session.endTime).format("LT"),
                "Total Session": moment
                  .utc(session.endTime - session.startTime)
                  .format("HH:mm"),
                Date: moment(d).format("DD-MMM-YYYY"),
                "On Time": convertMillisecondsToString(data.ontime),
                "OnLine Time": data?.onlineTime
                  ? convertMillisecondsToString(data.onlineTime)
                  : 0,
              });
            }
          }
        }

        await delay(100);
      }

      // for (const log of resp.data) {
      //   const onTime = calculate(
      //     log?.snapshot?.ontime !== undefined
      //       ? log.snapshot.ontime
      //       : 0
      //   );

      //   obj.push({
      //     Property: house.propertyName,
      //     Area: room.roomName,
      //     DeviceId: device.deviceId,
      //     Name: switchs.switchName,
      //     Date: moment(log.analysisDate).format("DD-MMM-YYYY"),
      //     "On TIme": onTime,
      //   });
      // }
    }

    setCsvData((state) => ({
      ...state,
      [stateKeyName]: {
        data: obj,
        summary: summaryReport,
        state: "Done",
      },
    }));
  }

  let ontime = async (deviceId, switchId) => {
    let res = await todayLogOntime(deviceId, switchId);

    return res;
  };

  const checkBlockedUnBlocked = (deviceId, switchId, css) => {
    let arr = css.split("");
    if (arr.length === 0) {
      arr.push("1");
    }
    if (arr[switchId] !== "0") {
      return "UnBlocked";
    } else {
      return "Blocked";
    }
  };

  useEffect(() => {
    let data = [];
    if (selectedCity === "ALL") {
      for (let house of HouseDetails) {
        let obj = {
          PropertyName: house.propertyName,
          PropertyId: house.propertyId,
          houseName: house.house.houseName,
          city: house.city,
        };
        data.push(obj);
      }
      // setCsvData(data);
    }
  }, [HouseDetails]);


  return (
    <div style={{ paddingTop: "15px", backgroundColor: "#ffffff" }}>
      <ScheduleModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        productDataItem={productDataItem}
      />
      <NewScheduleModal
        show={newModalShow}
        onHide={() => setNewModalShow(false)}
        productDataItem={productDataItem}
      />
      {/* <LogModel
        show={logModalShow}
        onHide={() => {
                      setLogModalShow(false)
                      dispatch(todayLogSave({success:true,message:"No Logs Found",final:[]}))
                    }}
        logSwitch={logSwitch}
      /> */}
      <LogModalOnTime
        show={logModalShow}
        onHide={() => {
          setLogModalShow(false);
          // dispatch(todayLogSave({success:true,message:"No Logs Found",final:[]}))
        }}
        logSwitch={logSwitch}
      />
      {/* <div
        style={{
          textAlign: "left",
          width: "fit-content",
          marginLeft: "30px",
          padding: "0.6rem",
          borderTopRightRadius: "10.2519px",
          borderTopLeftRadius: "10.2519px",
          backgroundColor: "#F9F9FB",
        }}
      >
        Breakdown
      </div> */}
      <div style={{ backgroundColor: "#F9F9FB" }}>
        <Stack
          direction="horizontal"
          gap={3}
          style={{
            marginLeft: "60px",
            paddingTop: "15px",
            marginBottom: "15px",
          }}
        >
          <Button
            variant="outline-secondary"
            size="sm"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              if (productDataItem.length > 0) {
                deviceBlock(
                  productDataItem,
                  setProductDataItem,
                  User,
                  0,
                  house?.house?._id,
                  dispatch,
                  citys,
                  selectedCity,
                  PropertyDetails.property
                );
              } else {
                notifyFailed("Select Atleast One Appliance");
              }
            }}
          >
            <SlashCircle style={{ marginRight: "5px" }} />
            Block manual switches
          </Button>
          <Button
            variant="outline-secondary"
            size="sm"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              if (productDataItem.length > 0) {
                deviceBlock(
                  productDataItem,
                  setProductDataItem,
                  User,
                  1,
                  house?.house?._id,
                  dispatch,
                  citys,
                  selectedCity,
                  PropertyDetails.property
                );
              } else {
                notifyFailed("Select Atleast One Appliance");
              }
            }}
          >
            <SlashCircle style={{ marginRight: "5px" }} />
            UnBlock manual switches
          </Button>
          <Button
            variant="outline-secondary"
            size="sm"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              if (productDataItem.length > 0) {
                newSchedule ? setNewModalShow(true) : setModalShow(true);
              } else {
                notifyFailed("Select Atleast One Appliance");
              }
            }}
          >
            <SlashCircle style={{ marginRight: "5px" }} />
            Schedule Manual Switches
          </Button>
          <Button
            variant="outline-secondary"
            size="sm"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              if (productDataItem.length !== 0) {
                TurnOffAllApi(dispatch, productDataItem, setProductDataItem);
              } else {
                notifyFailed("Select Atleast One Appliance");
              }
            }}
          >
            <SlashCircle style={{ marginRight: "5px" }} />
            Turn Off
          </Button>
          <Button
            variant="outline-secondary"
            size="sm"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              if (productDataItem.length !== 0) {
                TurnOnAllApi(dispatch, productDataItem, setProductDataItem);
              } else {
                notifyFailed("Select Atleast One Appliance");
              }
            }}
          >
            <SlashCircle style={{ marginRight: "5px" }} />
            Turn On
          </Button>
          <ConfirmationModel />
          {/* <CSVLink filename={`Logs_${moment(new Date()).format("DD-MMM-YYYY")}.csv`} style={{ textDecoration: "none" }} data={csvData}> */}
          {/* <Button
              variant="outline-secondary"
              size='sm'
              style={{
                display: "flex",
                alignItems: "center"
              }}
            >
              <SlashCircle style={{ marginRight: "5px" }} />
              Download CSV
            </Button>
          </CSVLink> */}
          {/* {!newSchedule && <AutoCutApi city={selectedCity} productDataItem={productDataItem} /> } */}
          <UpdateWifi productDataItem={productDataItem} />
          <Button
            variant="outline-secondary"
            size="sm"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            // onClick={() => {
            //   generateCsv();
            // }}
            onClick={() => {
              setCsvTimeSelectorModal(true);
            }}
          >
            <SlashCircle style={{ marginRight: "5px" }} />
            Report
          </Button>
          <MacIds deviceIds={productDataItem} />
        </Stack>
        <Table
          hover
          style={{
            textAlign: "left",
            marginLeft: "30px",
            width: "96%",
            borderRadius: "12px",
            overflow: "hidden",
          }}
        >
          <thead>
            <tr>
              <th>
                {" "}
                <Form.Check
                  aria-label="option 1"
                  name="AllChk"
                  id="AllChk"
                  onChange={(event) => selectAllBox(event.target.checked)}
                />
              </th>
              {/* <th className='tableHeading'>#</th> */}
              <th className="tableHeading">Property</th>
              <th className="tableHeading">City</th>
              {/* <th>Mqt</th> */}
              <th className="tableHeading">Area</th>
              <th className="tableHeading">Name</th>
              <th className="tableHeading">DeviceID</th>
              {/* <th className='tableHeading'>On Time</th> */}
              {/* <th className='tableHeading'>Device Status</th> */}
              {/* { !newSchedule && <th className="tableHeading">Auto Cut Time</th> } */}
              <th className="tableHeading">Schedule Manual Switches</th>
              <th className="tableHeading">Current Status</th>
              <th className="tableHeading">Connection Status</th>
              <th className="tableHeading">Switch Status</th>
              <th className="tableHeading">Connection Strength</th>
              {/* {!newSchedule && <th className="tableHeading">Wifi</th>} */}
              <th className="tableHeading">Manual Switch Status</th>
              <th className="tableHeading">Action</th>
            </tr>
          </thead>
          <tbody>
            {HouseDetails.length !== 0
              ? HouseDetails.map((house, ho) => {
                  if (!house.active) return;
                  if (
                    Object.keys(house).length !== 0 &&
                    house.rooms.length !== 0
                  ) {
                    return house.rooms.map((room, roi) => {
                      return room.devices.map((device, di) => {
                        if(!device.hasOwnProperty("connected") && !device.connected.hasOwnProperty("switchStatus")  && !device.connected.hasOwnProperty("connectionStatus")){
                           navigate('/')
                           dispatch(houseDataEmpty([]))
                        }
                        if (
                          device.hasOwnProperty("connected") &&
                          device.connected.connectionStatus.includes(
                            connectionStatus
                          ) && (device.connected?.switchStatus ? device.connected.switchStatus.includes(switchStatus) : true)
                        ) {
                          return device.switches.map((switchs, index) => {
                            if (
                              switchs.switchName.includes(selectedAppliance) &&
                              room.roomName.includes(selectedRoom) &&
                              switchs.deviceType !== 7 &&
                              house.propertyName.includes(selectedProperty)
                            ) {
                              return (
                                <tr key={switchs._id}>
                                  <td>
                                    <Form.Check
                                      aria-label="option 1"
                                      onChange={(event) =>
                                        productDataFilterAdd(
                                          {
                                            ...switchs,
                                            deviceId: device.deviceId,
                                            mqt: device.isMQTTDevice,
                                            houseName: house.propertyName,
                                            roomName: room.roomName,
                                            roomId: room._id,
                                            deviceType: switchs.deviceType,
                                            type:
                                              device.deviceId.substring(1, 3) ==
                                              "08"
                                                ? "nova"
                                                : "sync",
                                            houseId: house.house._id,
                                          },
                                          event.target.checked
                                        )
                                      }
                                      name="chk"
                                    />
                                  </td>
                                  {/* <td className='tableData'>`{device.deviceId} - {switchs.switchId} - {device.css}</td>  */}
                                  <td className="tableData">
                                    {house.propertyName}
                                  </td>
                                  <td className="tableData">{house.city}</td>
                                  {/* <td>{device.isMQTTDevice===true?"yes":"no"}</td> */}
                                  <td className="tableData">{room.roomName}</td>
                                  <td className="tableData">
                                    {switchs.switchName}
                                  </td>
                                  <td className="tableData">
                                    {device.deviceId}
                                  </td>
                                  {/* <OnTimeRow deviceId={device.deviceId} switchId={switchs.switchId}/> */}
                                  {/* {!newSchedule && (
                                  <td className="tableData">
                                    <AutoCutMap
                                      deviceId={device.deviceId}
                                      switchId={switchs.switchId}
                                    />
                                  </td>
                                )} */}
                                  <td className="tableData">
                                    {newSchedule ? (
                                      <NewFetchScheduleRow
                                        houseId={house.id}
                                        deviceId={device.deviceId}
                                      />
                                    ) : (
                                      <FetchScheduleRow
                                        deviceId={device.deviceId}
                                        switchId={switchs.switchId}
                                        roomName={room.roomName}
                                        propertyName={house.propertyName}
                                        switchName={switchs.switchName}
                                        houseId={house.id}
                                      />
                                    )}
                                  </td>
                                  <td>
                                    {switchs.switchState !== "0" ? (
                                      // <Button
                                      //   variant="outline-success"
                                      //   size="sm"
                                      //   style={{
                                      //     backgroundColor:
                                      //       "rgba(115, 241, 191, 0.3)",
                                      //   }}
                                      // >
                                      //   On
                                      // </Button>
                                      <span
                                        style={{
                                          color: "#198754",
                                          fontSize: "14px",
                                        }}
                                      >
                                        On
                                      </span>
                                    ) : (
                                      // <Button
                                      //   size="sm"
                                      //   style={{
                                      //     backgroundColor: "rgba(189, 0, 0, 0.3)",
                                      //   }}
                                      //   variant="outline-danger"
                                      // >
                                      //   Off
                                      // </Button>
                                      <span
                                        style={{
                                          color: "#dc3545",
                                          fontSize: "14px",
                                        }}
                                      >
                                        Off
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    <DeviceOnLIneOffLine
                                      deviceId={device.deviceId}
                                      mqt={device.isMQTTDevice}
                                      connected={
                                        device.connected.connectionStatus
                                      }
                                    />
                                  </td>
                                  <td>
                                    <SwitchLiveStatus
                                      switchStatus={device.connected?.switchStatus ? device.connected.switchStatus : "offline" }
                                    />
                                  </td>
                                  <td className="tableData">
                                    {device.strength}
                                  </td>
                                  {!newSchedule && (
                                    <td className="tabelData">
                                      <div>
                                        <div className="tableData">
                                          Wifi Name :- {device.sent_ws}
                                        </div>
                                        <div className="tableData">
                                          Password :- {device.sent_wp}
                                        </div>
                                      </div>
                                    </td>
                                  )}
                                  <td className="tableData">
                                    {checkBlockedUnBlocked(
                                      device.deviceId,
                                      switchs.switchId,
                                      device.ess
                                    )}
                                  </td>
                                  <td>
                                    <Stack direction="horizontal" gap={3}>
                                      {switchs.switchState !== "0" ? (
                                        <Button
                                          variant="outline-secondary"
                                          size="sm"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                          onClick={() =>
                                            TurnOffApi(
                                              dispatch,
                                              switchs,
                                              device.deviceId,
                                              house.house._id,
                                              device.isMQTTDevice
                                            )
                                          }
                                        >
                                          <SlashCircle
                                            style={{ marginRight: "5px" }}
                                          />
                                          Turn Off
                                        </Button>
                                      ) : (
                                        <Button
                                          variant="outline-secondary"
                                          size="sm"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                          onClick={() =>
                                            TurnOnApi(
                                              dispatch,
                                              switchs,
                                              device.deviceId,
                                              house.house._id,
                                              device.isMQTTDevice
                                            )
                                          }
                                        >
                                          <SlashCircle
                                            style={{ marginRight: "5px" }}
                                          />
                                          Turn On
                                        </Button>
                                      )}
                                      <Button
                                        variant="outline-secondary"
                                        size="sm"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                        onClick={() => {
                                          setLogSwitch({
                                            ...switchs,
                                            deviceId: device.deviceId,
                                            mqt: device.isMQTTDevice,
                                            houseName: house.propertyName,
                                            roomName: room.roomName,
                                            roomId: room._id,
                                            deviceType: switchs.deviceType,
                                            type:
                                              device.deviceId.substring(1, 3) ==
                                              "08"
                                                ? "nova"
                                                : "sync",
                                          });
                                          setLogModalShow(true);
                                        }}
                                      >
                                        <SlashCircle
                                          style={{ marginRight: "5px" }}
                                        />
                                        View Logs
                                      </Button>

                                      <EditHouseDetails
                                        data={{
                                          ...switchs,
                                          deviceId: device.deviceId,
                                          mqt: device.isMQTTDevice,
                                          houseName: house.propertyName,
                                          houseId: house.house._id,
                                          roomName: room.roomName,
                                          roomId: room._id,
                                          deviceType: switchs.deviceType,
                                          type:
                                            device.deviceId.substring(1, 3) ==
                                            "08"
                                              ? "nova"
                                              : "sync",
                                          city: house.city,
                                        }}
                                        city={citys}
                                      />
                                    </Stack>
                                  </td>
                                </tr>
                              );
                            }
                          });
                        }
                      });
                    });
                  }
                })
              : null}
          </tbody>
        </Table>
      </div>

      {csvTimeSelectorModal && (
        <Modal
          show={csvTimeSelectorModal}
          onHide={() => {
            setStartDate("");
            setEndDate("");
            setCsvTimeSelectorModal(false);
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px 0",
                marginBottom: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  // width: "400px",
                }}
              >
                <div style={{ marginRight: "20px" }}>
                  <div>Start Date</div>
                  <input
                    type="date"
                    onChange={(e) => setStartDate(e.target.value)}
                    max={maxDate}
                  />
                </div>
                <div style={{ marginRight: "20px" }}>
                  <div>End Date</div>
                  <input
                    type="date"
                    onChange={(e) => setEndDate(e.target.value)}
                    max={maxDate}
                  />
                </div>
                <Button
                  variant="outline-secondary"
                  size="sm"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    background: "blue",
                    color: "white",
                    height: "30px",
                    alignSelf: "flex-end",
                  }}
                  onClick={generateCsv}
                >
                  Generate CSV
                </Button>

                {/* {csvData.length !== 0 ? (
                  <CSVLink
                    className="btn btn-primary mt-4 btn-sm"
                    style={{
                      marginLeft: "10px",
                      height: "30px",
                      marginTop: "22px",
                      background: "rgb(0,0,255)",
                    }}
                    data={csvData}
                    filename={`Ontime_$.csv`}
                  >
                    Download CSV
                  </CSVLink>
                ) : null} */}
              </div>
              <span
                onClick={() => {
                  setCsvTimeSelectorModal(false);
                }}
                style={{ fontSize: "18px", cursor: "pointer" }}
              >
                X
              </span>
            </div>

            <div className="table-container">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>City</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {Object.keys(csvData).length > 0 &&
                    Object.keys(csvData).map((csvKeyName, index) => {
                      const fileDetails = csvKeyName.split("_");

                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{fileDetails[0]}</td>
                          <td>{fileDetails[1]}</td>
                          <td>{fileDetails[2]}</td>
                          <td>
                            {csvData[csvKeyName]?.state === "Done" ? (
                              <>
                                <CSVLink
                                  className="btn btn-primary btn-sm"
                                  style={{
                                    marginLeft: "10px",
                                    background: "rgb(0,0,255)",
                                  }}
                                  data={csvData[csvKeyName]?.data}
                                  filename={`${csvKeyName}_detailed.csv`}
                                >
                                  Download Detailed CSV
                                </CSVLink>
                                <CSVLink
                                  className="btn btn-primary btn-sm"
                                  style={{
                                    marginLeft: "10px",
                                    background: "rgb(0,0,255)",
                                  }}
                                  data={csvData[csvKeyName]?.summary}
                                  filename={`${csvKeyName}_summary.csv`}
                                >
                                  Download Summary CSV
                                </CSVLink>
                              </>
                            ) : (
                              "Processing..."
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}

function getDatesInRange(startDate, endDate) {
  startDate = new Date(startDate);
  endDate = new Date(endDate);

  const datesArray = [];
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    datesArray.push(new Date(currentDate).toISOString());
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return datesArray;
}

export default TableData;
