import moment from "moment";
import store from "..";
import { deviceOnTime } from "./ActionTypes";
import axios from "axios";
import { serverUrl } from "../../../ApiUri";

export const loadSwitchOnTime = (data) => {
    console.log(data,"pay");
  return {
    type: deviceOnTime.LOAD,
    payload: data,
  };
};

export function findDatesBetween(startDate, endDate) {
  // Create an array to store the dates between start and end dates
  var dates = [];

  // Convert start and end dates to JavaScript Date objects
  var currentDate = new Date(startDate);
  var lastDate = new Date(endDate);

  // Loop through each date starting from the start date until the end date
  while (currentDate <= lastDate) {
    // Add the current date to the array
    dates.push(new Date(currentDate));

    // Move to the next day
    currentDate.setDate(currentDate.getDate() + 1);
  }

  // Return the array of dates
  return dates;
}

const findDataInLocalStorage = (data, id, date) => {
  // Check if date is today or one of the two previous days
  // const currentDate = moment().startOf('day');
  // const targetDate = moment(date).startOf('day');
  // if (targetDate.isSameOrAfter(currentDate.subtract(2, 'days'))) {
  //     return false;
  // }
  if (moment(date).isSame(moment(), "day")) {
    return false; // If date is today, no need to check localStorage
  }

  // Check if data for the given id and date exists
  const idData = data[id];
  if (!idData || !idData[date]) {
    return false;
  }

  return true;
};

export const makeDatesFromRange = async (dateRage, deviceId) => {
  let switchOnTime = store.getState().switchOnTime;
  var startDate = new Date(dateRage.startDate).setHours(0, 0, 0, 0);
  var endDate = new Date(dateRage.endDate).setHours(0, 0, 0, 0);
  var datesBetween = findDatesBetween(startDate, endDate);
  console.log(datesBetween, startDate, endDate, deviceId);
  for (let d of datesBetween) {
    if (!findDataInLocalStorage(switchOnTime, deviceId, d)) {
      await devicelog(deviceId, d);
    }
  }
};
const devicelog = async (deviceId, date) => {
  let id = deviceId.split("$")[0];

  await axios
    .post(`${serverUrl.deviceLog}/legacy/dayAnalytics`, {
      deviceId: id,
      day: date,
    })
    .then((res) => {
      if (res.data.company === "success") {
        for (let id of res.data.final) {
          store.dispatch(
            loadSwitchOnTime({
              data: id,
              deviceId: `${id.deviceId}$${id.switchId}`,
              date: date,
            })
          );
        }
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
// const fetchDailyUnitsSnapshot =async (id,date)=>{

//     try {
//       let res = await postAllDataAws('/units/day',{
//             "deviceId": id,
//             "day": date
//         })
//         if(res.data.success){
//            store.dispatch(loadUnitSnapshot({data:res.data.data.snapshot,deviceId:id,date:date}))
//           return
//         }else{
//             return
//         }
//     } catch (error) {
//         return
//     }
// }
