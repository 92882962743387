import './App.css';
import Login from './component/login/Login';
import DashBord from './component/dashbord/DashBoard';
import 'bootstrap/dist/css/bootstrap.min.css'
import {createBrowserRouter,RouterProvider} from 'react-router-dom'
import NewDashboard from './component/dashbord/NewDashboard';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/new",
    element: <DashBord />,
  },
  {
    path: "/dashboard",
    element: <NewDashboard />
  }

])


function App() {
  return (
    <div className="App">
       <RouterProvider router={router}/>
    </div>
  );
}

export default App;
