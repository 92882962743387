import React, { useEffect, useState } from 'react'
import { Stack, Form } from 'react-bootstrap'
import './DashBoard.css'
import StatusBox from '../Common/StatusBox'
import TableData from '../Common/TableData'
import SideBar from '../Common/SideBar'
import Header from '../Common/Header'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllHouse, fetchHouse, getPropertiesApi, houseDataEmpty } from '../Redux/Action/PropertyAction'
import toast, { Toaster } from 'react-hot-toast'
import axios from 'axios'
// import { fetchSchedule } from '../Redux/Action/ScheduleAction'
import Spinner from '../Common/Spinner'


export default function DashBord() {
    let dispatch = useDispatch()
    let [loading,setloading] = useState(false)
    let HouseDetails = useSelector(state => state.HouseDetails)
    
    let [mostUsedAc, setMostUsedAc] = useState("Loading...")
    let [averageOnTime, setAverageOnTime] = useState("Loading...")
    let PropertyDetails = useSelector(state => state.PropertyDetails)
    let [selectedAppliance,setSelectedAppliance] = useState("")
    let [selectedProperty,setSelectedProperty] = useState("")    
    let [totalAppliances, setTotalAppliaces] = useState({ count: 0, countOn: 0, countOff: 0 })
    let [citys,setCitys] = useState([])
    let [rooms,setRooms] = useState([])
    let city =[]
    let [selectedRoom,setSelectedRoom] = useState("")
    let [selectedCity,setSelectedCity] = useState('Delhi')
    useEffect(() => {
        if (PropertyDetails.success === true) {
            city =[]
            
            for(let property of PropertyDetails.property){
                if(!city.includes(property.city)){
                    city.push(property.city)
                }
            }
            city.push("Delhi-NCR")
            city.push("ALL")
            fetchAllHouse(PropertyDetails.property,dispatch,city[0])
            
            setCitys(city)
        } else {
            
        }
    }, [PropertyDetails])
    useEffect(() => {
        // dispatch(houseDataEmpty([]))
        setTotalAppliaces({ count: 0, countOn: 0, countOff: 0 })
        setMostUsedAc('Loading...')
        setAverageOnTime('Loading...')
        getPropertiesApi(dispatch)
    }, [])
  
    useEffect(()=>{
        if(HouseDetails.length===0){
            setloading(true)
        }
        
        if(HouseDetails.length===0) return
        if(HouseDetails.length!==0){
            // fetchSchedule(HouseDetails)
            setloading(false)
        let count = 0
        let countOn = 0;
        let countOff = 0;
        let devices = [];
        let roomName=[]
        let ontime =0
        let yesterdayOntime=0
        let currentToggle =0
        for(let house of HouseDetails){
        for (let room of house.rooms) {
            if(!roomName.includes(room.roomName) && room.devices.length!==0){
                roomName.push(room.roomName)
            }
                    for (let device of room.devices) {
                        
                        
                        for (let swit of device.switches) {
                            
                            if(selectedAppliance!=="" && swit.switchName.includes(selectedAppliance)){
                                devices.push(device.deviceId)
                            if (swit.switchState === "0") {
                                countOff += 1
                                count +=1
                            }
                            if (swit.switchState !== "0") {
                                countOn += 1
                                count +=1
                            }}else if(selectedAppliance===""){
                                devices.push(device.deviceId)
                                if (swit.switchState === "0") {
                                    countOff += 1
                                    count +=1
                                }
                                if (swit.switchState !== "0") {
                                    countOn += 1
                                    count +=1
                                }
                            }
                        }
                    }
                }
            }
        setRooms(roomName)
            setTotalAppliaces({ count: count, countOn: countOn, countOff: countOff })
        
           
        }
    },[HouseDetails])

    useEffect(()=>{
        if(HouseDetails.length===0) return
        let count = 0
        let countOn = 0;
        let countOff = 0;
        let devices = [];
        
        for(let house of HouseDetails){
            for (let room of house.rooms) {
                        for (let device of room.devices) {
                            for (let swit of device.switches) {
                                if(selectedAppliance!=="" && swit.switchName.includes(selectedAppliance)){
                                devices.push(device.deviceId)
                                if (swit.switchState === "0") {
                                    countOff += 1
                                    count +=1
                                }
                                if (swit.switchState !== "0") {
                                    countOn += 1
                                    count +=1
                                }}else if(selectedAppliance===""){
                                    devices.push(device.deviceId)
                                    if (swit.switchState === "0") {
                                        countOff += 1
                                        count +=1
                                    }
                                    if (swit.switchState !== "0") {
                                        countOn += 1
                                        count +=1
                                    }
                                }
                            }
                        }
                    }
                }
                 setTotalAppliaces({ count: count, countOn: countOn, countOff: countOff })
                // axios.post(`https://analytics.alistetechnologies.com:443/analytic/TodayLogsByDateDeviceHouse`, {
                //     devices: devices,
                //     roomId: "dfs"
                // }).then(function (res) {
                //     for(let house of HouseDetails){
                //     for (let room of house.rooms) {
                //         for (let device of room.devices) {
                //             if (device.deviceId === res.data.finalsend[0].deviceId) {
                //                 for (let swit of device.switches) {
                //                     if (swit.switchId === res.data.finalsend[0].switchId) {
                //                         setMostUsedAc(swit.switchName)
                //                     }
                //                 }
                //             }
        
                //         }
                //     }
                // }
                //     for(let onTimeSingle of res.data.finalsend){
                //         ontime +=Number(onTimeSingle.onTime)
                //         for(let i of Object.keys(onTimeSingle.toggles)){
                //             currentToggle +=onTimeSingle.toggles[i]
                //         }
        
                //     }
                //     const durationInMilliseconds = ontime/res.data.finalsend.length;
             
                //     const seconds = Math.floor(durationInMilliseconds / 1000);
                //     const hours = Math.floor(seconds / 3600);
                //     const minutes = Math.floor((seconds % 3600) / 60);
                //     const remainingSeconds = seconds % 60;
                    
                //     const formattedTime = `${hours}h ${minutes.toString().padStart(2, '0')}m `;
                //     setAverageOnTime(formattedTime)
                // }).catch(function (err) {
                //     console.log(err);
                // })
    },[selectedAppliance])
  

    return (
        <div style={{ display: "flex" }}>
            <SideBar />
            <div>
                <Header />
                <div
                    style={{ width: "95vw", height: "90vh", overflow: "scroll", backgroundColor: "#F9F9FB" }}
                    className='dashboard'
                >
                    <Stack direction="horizontal" gap={3} style={{ paddingLeft: "30px", paddingTop: "30px", backgroundColor: "#FFFFFF" }}>
                      
                        <Form.Group className="mb-3" style={{
                            width: "18%",
                            fontFamily: 'Manrope',
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "12.9008px",
                            lineHeight: "19px",
                            color: "#000000",
                            textAlign:"left"
                        }}>
                        <Form.Label sx={{marginLeft:"3px"}}>City</Form.Label>
                        <Form.Select 
                          style={{
                            fontFamily: 'Manrope',
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "12.9008px",
                            lineHeight: "19px",
                            color: "#000000",
                            backgroundColor: "#F9F9FB"
                        }}
                          onChange={(e)=>{
                            setSelectedCity(e.target.value)
                            setAverageOnTime('Loading...')
                            setMostUsedAc('Loading...')
                            // setloading(true)
                            // fetchAllHouse(PropertyDetails.property,dispatch,e.target.value)
                            setTotalAppliaces({ count: 0, countOn: 0, countOff: 0 })
                        }}  
                        >
                            
                            {
                                citys.length !== 0 ?
                                    citys.map((city) => {
                                        return (
                                            <option key={city} value={city}>{city}</option>
                                        )
                                    }) : null
                            }
                        </Form.Select>
                        </Form.Group>
                        
                        <Form.Group className="mb-3" style={{
                            width: "18%",
                            fontFamily: 'Manrope',
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "12.9008px",
                            lineHeight: "19px",
                            color: "#000000",
                            textAlign:"left"
                        }}>
                        <Form.Label sx={{marginLeft:"3px"}}>Property</Form.Label>
                        <Form.Select 
                          style={{
                            fontFamily: 'Manrope',
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "12.9008px",
                            lineHeight: "19px",
                            color: "#000000",
                            backgroundColor: "#F9F9FB"
                        }}
                          onChange={(e)=>{
                            setSelectedProperty(e.target.value)
                           
                           }}  
                        >
                            <option key={"ALL1"} value={""}>ALL</option>
                            {
                               (Object.keys(PropertyDetails).length!==0 && PropertyDetails.property.length !== 0) ?
                               PropertyDetails.property.map((property) => {
                                        return (
                                            <option key={property._id} value={property.propertyName}>{property.propertyName}</option>
                                        )
                                    }) : null
                            }
                        </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" style={{
                            width: "18%",
                            fontFamily: 'Manrope',
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "12.9008px",
                            lineHeight: "19px",
                            color: "#000000",
                            textAlign:"left"
                        }}>
                        <Form.Label>Room Name</Form.Label>
                        <Form.Select
                          style={{
                            fontFamily: 'Manrope',
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "12.9008px",
                            lineHeight: "19px",
                            color: "#000000",
                            backgroundColor: "#F9F9FB"
                        }}
                          onChange={(e)=>{
                              setSelectedRoom(e.target.value)
                        }}  
                        >
                        <option key={"All"} value={""}>{"All"}</option>

                            {
                                rooms.length !== 0 ?
                                    rooms.map((room) => {
                                        return (
                                            <option key={room} value={room}>{room}</option>
                                        )
                                    }) : null
                            }
                        </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" style={{
                            width: "18%",
                            fontFamily: 'Manrope',
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "12.9008px",
                            lineHeight: "19px",
                            color: "#000000",
                            textAlign:"left"
                        }}>
                        <Form.Label>Appliance</Form.Label>
                        <Form.Select 
                          style={{
                            fontFamily: 'Manrope',
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "12.9008px",
                            lineHeight: "19px",
                            color: "#000000",
                            backgroundColor: "#F9F9FB"
                        }}
                          onChange={(e)=>{
                            setAverageOnTime('Loading...')
                            setMostUsedAc('Loading...')
                            setTotalAppliaces({ count: 0, countOn: 0, countOff: 0 })
                            if(e.target.value!==""){
                            setSelectedAppliance(e.target.value)  
                            }else{
                                setSelectedAppliance("")
                            }                       
                        }}  
                        >
                            <option key={"Appliances"} value={""}>{"ALL"}</option>        
                            <option key={"AC"} value={"AC"}>{"AC"}</option>      
                            <option key={"Bain Marie"} value={"Bain Marie"}>{"Bain Marie"}</option>           
                        </Form.Select>
                        </Form.Group>
                        <div className='roomNumber mt-2'>
                            <span className='roomTotalHeader'>Total {selectedAppliance===""?"Appliances":selectedAppliance}'s </span><span className='roomTotalNumber'>{totalAppliances.count}</span>
                        </div>

                    </Stack>
                    <Stack direction="horizontal" gap={3} className='pt-3' style={{ paddingLeft: "30px", backgroundColor: "#FFFFFF" }}>
                        <StatusBox backgroundColor={"#FFE2E6"} status={`${selectedAppliance===""?"Appliances":selectedAppliance}'s On `} number={totalAppliances.countOn} circleColor={"#FA5A7C"} />
                        <StatusBox backgroundColor={"#FFF4DE"} status={`${selectedAppliance===""?"Appliances":selectedAppliance}'s Off`} number={totalAppliances.countOff} circleColor={"#FE947A"} />
                        {/* <StatusBox backgroundColor={"#DCFCE7"} status={"Avg On time"} number={averageOnTime} circleColor={"#3CD956"} /> */}
                        {/* <StatusBox backgroundColor={"#F4E8FF"} status={"Most Used Appliance"} number={mostUsedAc} circleColor={"#BF83FD"} /> */}
                    </Stack>
                    {
                         loading===true?<Spinner/>:<TableData  citys={citys} selectedCity={selectedCity} selectedAppliance={selectedAppliance} selectedRoom={selectedRoom} selectedProperty={selectedProperty} city={city}/>
                    }
                    {/* <TableData  citys={citys} selectedCity={selectedCity}/> */}
                </div>
            </div>
            <Toaster position="top-center" />
        </div>

    )
} 
