import axios from 'axios';
import moment from 'moment';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { Button, Form, Modal, Table } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { useSelector } from 'react-redux';
import { serverUrl } from '../../ApiUri';
import { findDatesBetween, makeDatesFromRange } from '../Redux/Action/devicelog';
import LogModalOnTimeTr from './LogModalOnTimeTr';


export default function LogModalOnTime(props) {
  let [logs, setLogs] = useState([])
  let [message, setMessage] = useState('')
  let switchOnTime = useSelector(state => state.switchOnTime)

  const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 3)));
  const [endDate, setEndDate] = useState(new Date(new Date().setDate(new Date().getDate() - 1)));
  const [csv, setCsv] = useState([]);
  const [showMoreDetails, setShowMoreDetails] = useState(false);

  const maxDate = moment(new Date()).format('yyyy-MM-DD');

  const { logSwitch, show } = props
  

  const devicelog = async () => {
    alert("call")
    await axios.post(`${serverUrl.deviceLog}/legacy/dayAnalytics`, {
      "deviceId": logSwitch.deviceId,
      "day": "2024-04-30T18:30:00.000Z"
    }).then((res) => {
      // console.log(res);
      if (res.data.company === "success") {
        for (let id of res.data.final) {
          // console.log(id.ontime, id.switchId, id.deviceId, "ontime");
        }

      }
    }).catch(err => {
      console.log(err);
    })
  }

  function getAnalysisData() {
    axios
      .post(
        "https://analytics.alistetechnologies.com:443/analytic/v2/getSnapShotData",
        // {
        //     "dSIDs": ["S015365$0"],
        // "date": "2023-10-25T11:56:10.099Z",
        // "firstDate": "2023-10-11T11:56:10.099Z",
        // "lastDate": "2023-10-27T11:56:10.099Z"
        // }
        {
          dSIDs: [`${logSwitch.deviceId}$${logSwitch.switchId}`],
          date: new Date(),
          firstDate:
            startDate === "" && endDate === ""
              ? new Date(new Date().setDate(new Date().getDate() - 3))
              : new Date(startDate),
          lastDate:
            endDate === "" && startDate === ""
              ? new Date(new Date().setDate(new Date().getDate() - 1))
              : new Date(endDate),
        }
      )
      .then(function (res) {
        if (res.data.result === "success") {
          setLogs(res.data.data[0].data);
        } else {
          setMessage("Data Not Found");
        }


      })
      .catch(function (err) {
        setMessage("Data Not Found");
      });
  }

  // function generateCsv () {
  //     const csvData = [];
  //     let i = 1;
  //     for (const log of logs) {
  //         csvData.push({
  //             '#': i,
  //             'Date': moment(log.analysisDate).format("DD-MMM-YYYY"),
  //             'OnTime': calculate(
  //                         log?.snapshot?.ontime !== undefined
  //                           ? log.snapshot.ontime
  //                           : 0)
  //         })
  //         i = i+1;
  //     }
  //     setCsv(csvData);
  // }

  useEffect(() => {

    if (show === false) return
    setShowMoreDetails(false);
    makeDatesFromRange({ startDate, endDate }, `${logSwitch.deviceId}$${logSwitch.switchId}`)

    setMessage("")
    setLogs([])
    // getAnalysisData();
  }, [logSwitch, startDate, endDate])

  const calculate = (date) => {
    let milliseconds = date



    const seconds = Math.floor((milliseconds / 1000) % 60);

    const minutes = Math.floor((milliseconds / 1000 / 60) % 60);

    const hours = Math.floor((milliseconds / 1000 / 60 / 60) % 24);

    const formattedTime = [
      hours.toString().padStart(2, "0"),
      minutes.toString().padStart(2, "0"),
      seconds.toString().padStart(2, "0")
    ];

    return `${hours.toString().padStart(2, "0")}h ${minutes.toString().padStart(2, '0')}m `;
  }
  const makeDateData = (s, e) => {
    let ss = new Date(s).setHours(0, 0, 0, 0)
    let end = new Date(e).setHours(0, 0, 0, 0)
    return findDatesBetween(ss, end)
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px 0",
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              // width: "400px",
            }}
          >
            <div style={{ marginRight: "20px" }}>
              <div>Start Date</div>
              <input
                type="date"
                value={moment(startDate).format('yyyy-MM-DD')}
                onChange={(e) => setStartDate(e.target.value)}
                max={maxDate}
              />
            </div>
            <div style={{ marginRight: "20px" }}>
              <div>End Date</div>
              <input
                type="date"
                value={moment(endDate).format('yyyy-MM-DD')}
                onChange={(e) => setEndDate(e.target.value)}
                max={maxDate}
              />
            </div>
            <Button
              variant="outline-secondary"
              size="sm"
              style={{
                display: "flex",
                alignItems: "center",
                background: "blue",
                color: "white",
                height: "30px",
                alignSelf: "flex-end",
              }}
              onClick={()=>    makeDatesFromRange({ startDate, endDate }, `${logSwitch.deviceId}$${logSwitch.switchId}`)}
            >
              Get Data
            </Button>
            {/* <Button
                variant="outline-secondary"
                size="sm"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={generateCsv}
              >
                Get Csv
              </Button>
        {csv.length !== 0 ? (

              <CSVLink
                className="btn btn-primary mt-3"
                data={csv}
                filename={`Ontime_${startDate}_to_${endDate}.csv`}
              >
                Get CSV
              </CSVLink> ) : null } */}
          </div>
          <span
            onClick={() => {
              props.onHide();
              setLogs([]);
              setMessage("");
            }}
            style={{ fontSize: "18px", cursor: "pointer" }}
          >
            X
          </span>
        </div>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Date</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Session</th>
              <th>Online Time</th>
              <th>Total Ontime</th>
            </tr>
          </thead>
          <tbody>
            {
              makeDateData(startDate, endDate).map((date, i) => {
                const idData = switchOnTime[`${logSwitch.deviceId}$${logSwitch.switchId}`];
                if (idData) {
                  return (
                    <LogModalOnTimeTr date={date} logSwitch={logSwitch} />
                  );
                }
            
              })}
          </tbody>
        </Table>

      </Modal.Body>
    </Modal>
  );
}
