import { useEffect, useState } from 'react';
import { SlashCircle } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';
import { notifyFailed, notifySuccess } from '../utlity/Notification';
import { updateWifi } from '../utlity/function';

function UpdateWifi({ productDataItem }) {
  
  const [show, setShow] = useState(false);

  let [btn, setBtn] = useState("Submit")
  const [wifiDetails,setWifiDetails] = useState({
    "ws": "",
    "wp": ""
  })
  const dispatch = useDispatch()
  const handleClose = () => {
    setBtn('Submit')
    setShow(false);
}
  const handleShow = () => {
    setBtn('Submit')
    setShow(true);
}
  
 const handleSubmit = async()=>{
    setBtn('Loading...')
    let UniqueDeviceid = []
    for(let i of productDataItem){
        if(!UniqueDeviceid.includes(i.deviceId)){
            UniqueDeviceid.push(i.deviceId)
        }
    }
    let data = []
    for(let i of UniqueDeviceid){
        data.push({
            deviceId:i,
            ...wifiDetails
        })
    }
    for(let i of data){
       await updateWifi(i)
       setTimeout(console.log(i), 200);
    }
    setBtn('Submit')
    handleClose()

 }


  return (
    <>
      <Button
        variant="outline-secondary"
        size='sm'
        style={{
          display: "flex",
          alignItems: "center"
        }}
        onClick={() => {
          if (productDataItem.length !== 0) {
            handleShow()
          } else {
            notifyFailed("Select Atleast One Appliance")
          }
        }}
      >
        <SlashCircle style={{ marginRight: "5px" }} />
        Update Wifi
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Wifi Credential Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" style={{
            width: "40%",
            fontFamily: 'Manrope',
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "12.9008px",
            lineHeight: "19px",
            color: "#000000",
            textAlign: "left"
          }}>
            <Form.Label sx={{ marginLeft: "3px" }}>Wifi SSID</Form.Label>
        <Form.Control 
          type="text" 
          placeholder="Wifi Name" 
          value={wifiDetails.ws}
          onChange={(e)=>setWifiDetails({...wifiDetails,ws:e.target.value})}
        />
            
          </Form.Group>
          <Form.Group className="mb-3" style={{
            width: "40%",
            fontFamily: 'Manrope',
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "12.9008px",
            lineHeight: "19px",
            color: "#000000",
            textAlign: "left"
          }}>
            <Form.Label sx={{ marginLeft: "3px" }}>Wifi Password</Form.Label>
        <Form.Control 
           type="text" 
           placeholder="Wifi Password" 
           value={wifiDetails.wp}
          onChange={(e)=>setWifiDetails({...wifiDetails,wp:e.target.value})}
        />
            
          </Form.Group>
        
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" style={{ pointerEvents: btn === "Loading..." ? "none" : "" }} onClick={handleSubmit}>
            {btn}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UpdateWifi;