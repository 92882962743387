import React from 'react'
import "./Header.css"
import { useDispatch, useSelector } from 'react-redux'
import { emptyTodayLogs } from '../Redux/Action/LogsAction';
import { emptySchedule } from '../Redux/Action/ScheduleAction';
import { emptyProperty, houseDataEmpty } from '../Redux/Action/PropertyAction';
import { emptyAutoCut } from '../Redux/Action/AutoCut';
import { deviceOnTime, newSchedule } from '../Redux/Action/ActionTypes';
import { useNavigate } from 'react-router-dom';
import { BoxArrowRight } from "react-bootstrap-icons";


export default function Header() {
  const user = useSelector(state=>state.User);

  let dispatch = useDispatch();
  const navigate = useNavigate();
      const handleLogout = (event) => {
        // dispatch(houseDataEmpty([]))
        dispatch(emptyTodayLogs({}));
        dispatch(emptySchedule([]));
        dispatch(emptyProperty({}));
        dispatch(emptyAutoCut([]));
        dispatch(houseDataEmpty([]));
        dispatch({ type: deviceOnTime.EMPTY });
        dispatch({ type: newSchedule.EMPTY_SCHEDULE_NEW });
        navigate("/");
      };
  return (
    <div 
      style={{
        width:"100%",
        height:"10vh",
        backgroundColor:"#FFFFFF",
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        padding:"0px 5%"
      }}
    >
        <div>
            <span className='clientHeader'>Stanza </span>
            <span className='clientSubHeader'>Living</span>
        </div>
        <div style={{
            display:"flex"
        }}>
           
            <div
             style={{
                display:"flex",
                flexDirection:"row",
                marginLeft:"10px",
                justifyContent: "center",
                gap:"10px",
                alignItems: 'center'
             }}
            >
                <span className='userName'>{user.userName}</span>
                <span className='userName'>v 2.60</span>
                {/* <span className='admin'>admin</span> */}
                <BoxArrowRight style={{color: 'red', fontSize: 25, cursor: "pointer"}} onClick={() => handleLogout()} />
            </div>
        </div>

    </div>
  )
}
