import React from "react";

export default function DeviceOnLIneOffLine({ deviceId, mqt, connected }) {
  // let [online, setOnline] = useState("");
  // const deviceCheck = () => {
  //   if (mqt === false) {
  //     axios
  //       .get(`${serverUrl.a3}/v2/admin/deviceOnline/${deviceId}`)
  //       .then(function (res) {
  //         if (res.status === 200) {
  //           setOnline(res.data.status);
  //         }
  //       })
  //       .catch(function (error) {
  //         setOnline("offline");
  //       });
  //   } else {
  //     axios
  //       .post(`${serverUrl.mqt}/connectionStatus`, {
  //         deviceId: deviceId,
  //       })
  //       .then(function (res) {
  //           const response = res.data.data;
  //         if (res.data.success === true) {
  //           if (res.data.data.connected === true) {
  //             setOnline("Live");
  //           } else {
  //               // Check if disconnected Time exists
  //               if (response?.disconnectTime) {

  //                   // Calculate if disconnectTime is less then 12 hrs
  //                   const timeSinceDisconnected = convertMillisecondsToTimeObject(response.disconnectTime);

  //                   if (timeSinceDisconnected.hours > 12) {
  //                       setOnline('Not Live')
  //                   } else {
  //                       setOnline('Live')
  //                   }

  //                   return;
  //               }
                
  //               // If disconnected Time does not exists
  //               setOnline("Offline");
  //           }
  //         } else {
  //           setOnline("Offline");
  //         }
  //       })
  //       .catch(function (error) {
  //         setOnline("Offline");
  //       });
  //   }
  // };
  // useEffect(() => {
  //   deviceCheck();
  // }, []);
  return (
    <>
      {
        // connected === "Live" ? (
        //   <span style={{ color: "#198754", fontSize: "14px" }}>Live</span>
        // ) : connected === "Not Live" ? (
        //   <span style={{ color: "#dc3545", fontSize: "14px" }}>Not Live</span>
        // ) : (
        //   <span style={{ color: "#dc3545", fontSize: "14px" }}>Offline</span>
        // )

        //////////////////////////// OLD /////////////
          //  online==="online"? <Wifi/>:(online===""?"Loading...":<WifiOff/>)
        connected === "online" ? (
          <span style={{ color: "#198754", fontSize: "14px" }}>Online</span>
        ) : (
          <span style={{ color: "#dc3545", fontSize: "14px" }}>Offline</span>
        )
      }
    </>
  );
}
