import { newSchedule } from "../Action/ActionTypes";

export const fetchSchedule = (state = [], action) => {
  switch (action.type) {
    case newSchedule.ADD_SCHEDULE:
      if (state.some(schdl => schdl._id === action.payload._id)) {
        return state
      }
      return [...state, action.payload];
    case newSchedule.UPDATE_SCHEDULE:
      return action.payload;
    case newSchedule.REMOVE_SCHEDULE:
      return state.filter((schedule) => schedule._id !== action.payload);
    case newSchedule.EMPTY_SCHEDULE_NEW:
      return [];
    default:
      return state;
  }
};
