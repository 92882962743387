import React from 'react'

export default function SideBar() {
  return (
    <div style={{ width: "5%", height: "100vh", backgroundColor: "#FFFFFF" }}>
      <div
        style={{
          height: "10vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          style={{ height: "29.78px", width: "31.91px" }}
          src={process.env.PUBLIC_URL + "./assets/Img/Logo.png"}
        />
      </div>
      {/* <div
        style={{
          height: "10vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <a
          href="/dashboard"
          style={{
            flexDirection: "column",
            alignItems: "center",
            display: "flex",
            textDecoration: "none",
            color: "black",
          }}
        >
          <img
            style={{ height: "25.11px", width: "28.83px" }}
            src={process.env.PUBLIC_URL + "./assets/Img/Home.png"}
          />
          <span style={{ fontSize: "8.76585px", lineHeight: "13px" }}>
            Schedule
          </span>
        </a>
      </div> */}
      <div
        style={{
          height: "10vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <a
          href="/dashboard"
          style={{
            flexDirection: "column",
            alignItems: "center",
            display: "flex",
            textDecoration: "none",
            color: "black",
          }}
        >
          <img
            style={{ height: "25.11px", width: "28.83px" }}
            src={process.env.PUBLIC_URL + "./assets/Img/Home.png"}
          />
          <span style={{ fontSize: "8.76585px", lineHeight: "13px" }}>
            Schedule v2
          </span>
        </a>
      </div>
    </div>
  );
}
