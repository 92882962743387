export const serverUrl ={
    test:"https://test.alistetechnologies.com:8443",
    aws:"https://web.alistetechnologies.com",
    a3:"https://a3.alistetechnologies.com",
    main:"https://subscriptioncloud.alistetechnologies.com",
    s:"https://int.alistetechnologies.com",
    pay:"https://web.alistetechnologies.com/payments/",
    partialPay:"https://web.alistetechnologies.com/payments/partial/",
    mqt:"https://keiozfbox5.execute-api.ap-south-1.amazonaws.com/default",
    updatewifi:"https://v3kglrcssj.execute-api.ap-south-1.amazonaws.com/default",
    deviceLog:'https://c6qofus8ee.execute-api.ap-south-1.amazonaws.com/default',
}