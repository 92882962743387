import { schedule } from "../Action/ActionTypes";

export const fetchScheduleReducer = (state=[],action)=>{
    switch (action.type) {
        case schedule.FETCH_SCHEDULE:
            return action.payload
        case schedule.EMPTY_SCHEDULE:
            return action.payload
        case schedule.REMOVE_SCHEDULE:
            let single = [...state]
            single=  state.map(schedule=>{
                 let newSchedule = {...schedule}
                  if(schedule.schedule.length!==0){
                       newSchedule = schedule.schedule.filter((ss)=>{
                        
                          if (ss.mainId!==action.payload) {
                              return ss
                          }
                       })
                       
                       return {...schedule,schedule:newSchedule}
                  }else{
                      return schedule
                  }
                  
               })

           return single
        case schedule.ADD_SINGLE_SCHEDULE:
                let newSchedule =  [...state].filter((schedule)=>{
                    if(schedule.id!==action.payload.id){
                        return schedule
                    }
                  })
                  
                  return [...newSchedule,action.payload]
        case schedule.REMOVE_MULTPLESCHEDULE:
             let sch = [...state]

              sch=  state.map(schedule=>{
                   let newSchedule = {...schedule}
                    if(schedule.schedule.length!==0){
                         newSchedule = schedule.schedule.filter((ss)=>{
                            if (!action.payload.includes(ss.mainId)) {
                                return ss
                            }
                         })
                         return {...schedule,schedule:newSchedule}
                    }else{
                        return schedule
                    }
                    
                 })

             return sch
        
       
             default:
            return state
    }
}