import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { PencilFill, SlashCircle } from "react-bootstrap-icons";
import { serverUrl } from "../../ApiUri";
import { notifySuccess } from "../utlity/Notification";
import {
  fetchHouseSingle,
  getPropertiesApi,
} from "../Redux/Action/PropertyAction";

function EditHouseDetails({ data, city }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let [btn, setBtn] = useState("Submit");
  const [detail, setDetail] = useState({
    houseName: data.houseName,
    area: data.roomName,
    switchName: data.switchName,
    city: data.city,
  });
  useEffect(() => {
    setDetail({
      houseName: data.houseName,
      area: data.roomName,
      switchName: data.switchName,
      city: data.city,
    });
  }, [data]);
  const handleChange = (e) => {
    setDetail({
      ...detail,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async () => {
    setBtn("Loading...");

    await axios
      .post(`${serverUrl.main}/app/stanza/editHouseAndPropertyName`, {
        houseId: data.houseId,
        name: detail.houseName,
        city: detail.city,
      })
      .then((res) => {
        
      })
      .catch(function (err) {
        console.log(err);
      });

    await axios
      .post(`${serverUrl.main}/app/stanza/editRoomName`, {
        id: data.roomId,
        name: detail.area,
      })
      .then((res) => {
        
      })
      .catch(function (err) {
        console.log(err);
      });

    await axios
      .post(`${serverUrl.main}/app/stanza/editSwitchName`, {
        deviceId: data.deviceId,
        switchId: data.switchId,
        name: detail.switchName,
      })
      .then((res) => {
        
      })
      .catch(function (err) {
        console.log(err);
      });
    await fetchHouseSingle(data.houseId, detail.houseName, detail.city);
    setBtn("Submit");

    notifySuccess("Details Updated Successfully");
    setShow(false);
  };
  return (
    <>
      <Button
        variant="outline-secondary"
        size="sm"
        style={{
          display: "flex",
          alignItems: "center",
        }}
        onClick={() => handleShow()}
      >
        <SlashCircle style={{ marginRight: "5px" }} />
        Edit
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Detaills</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Property Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="property name"
              name="houseName"
              value={detail.houseName}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>City</Form.Label>
            <Form.Select
              style={{
                fontFamily: "Manrope",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "12.9008px",
                lineHeight: "19px",
                color: "#000000",
                backgroundColor: "#F9F9FB",
              }}
              placeholder="city"
              name="city"
              value={detail.city}
              onChange={handleChange}
            >
              {city.length !== 0
                ? city.map((cit) => {
                    return (
                      <option key={cit} value={cit}>
                        {cit}
                      </option>
                    );
                  })
                : null}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Area</Form.Label>
            <Form.Control
              type="text"
              placeholder="area name"
              name="area"
              value={detail.area}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Appliance Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="appliance name"
              name="switchName"
              defaultValue={detail.switchName}
              onChange={handleChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

          <Button
            variant="primary"
            type="submit"
            style={{ pointerEvents: btn === "Loading..." ? "none" : "" }}
            onClick={handleSubmit}
          >
            {btn}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditHouseDetails;
