import { useEffect, useState } from "react";
import { SlashCircle } from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { getMacids } from "../../utlity/apis";
import { Table } from "react-bootstrap";
import { CSVLink } from "react-csv";

function MacIds({deviceIds}) {
  const [show, setShow] = useState(false);
  const [finalData, setFinalData] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (!show) return;

    getMacIds();

  }, [show])

  async function getMacIds() {
      let data = [];
      let finalData = [];
      let csvData = {}
        for (const house of deviceIds) {
            data.push(house.deviceId)
            
               csvData[house.deviceId] = {
                    houseName: house.houseName,
                    roomName: house.roomName,
                    switchId: house.switchId,
                    switchName: house.switchName
                }
            
        }
            const resp = await getMacids({"deviceIds":data});
            

        for (const mac of resp.data.devices) {
            csvData[mac.deviceId] = {
                mac: mac.mac,
                ...csvData[mac.deviceId],
            }
        }

        for (const device of Object.keys(csvData)) {
            finalData.push({
              deviceId: device,
              mac: csvData[device].mac,
              houseName: csvData[device].houseName,
              roomName: csvData[device].roomName,
              switchId: csvData[device].switchId,
              switchName: csvData[device].switchName,
            });
        }

        setFinalData(finalData);
    }


  return (
    <>
      <Button
        variant="outline-secondary"
        size="sm"
        style={{
          display: "flex",
          alignItems: "center",
        }}
        // onClick={() => {
        //   generateCsv();
        // }}
        onClick={() => {
          setShow(true);
        }}
      >
        <SlashCircle style={{ marginRight: "5px" }} />
        MacIds
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Get MacIds</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px 0",
              marginBottom: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // width: "400px",
              }}
            >
            </div>
            
          </div>

          <div className="table-container">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>DeviceId</th>
                  <th>Mac</th>
                  <th>House Name</th>
                  <th>Room Name</th>
                  <th>Switch Id</th>
                  <th>Switch Name</th>
                </tr>
              </thead>

              <tbody>
                {finalData.length > 0 && finalData.map(d => (
                    <tr>
                        <td>{d.deviceId}</td>
                        <td>{d.mac}</td>
                        <td>{d.houseName}</td>
                        <td>{d.roomName}</td>
                        <td>{d.switchId}</td>
                        <td>{d.switchName}</td>
                    </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {finalData.length !== 0 ? (
            <Button>
                  <CSVLink
                    // className="btn btn-primary mt-4 btn-sm"
                    // style={{
                    //   marginLeft: "10px",
                    //   height: "30px",
                    //   marginTop: "22px",
                    //   background: "rgb(0,0,255)",
                    // }}
                    style={{
                        color: "white",
                        textDecoration: "none"
                    }}
                    data={finalData}
                    filename={`macIds.csv`}
                  >
                    Download CSV
                  </CSVLink>

                </Button>
                ) : null}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MacIds;
