import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import './ScheduleModal.css'
import { Form, InputGroup, Stack, Table } from 'react-bootstrap';
import { SlashCircle, Trash } from 'react-bootstrap-icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editSchedule, emptySchedule, fetchSingleSchedule } from '../Redux/Action/ScheduleAction';
import moment from 'moment';
import { notifyFailed, notifySuccess } from '../utlity/Notification';
import { findHoseDetails } from '../Redux/Action/PropertyAction';

export default function ScheduleEdit(props) {
  const { selectedSchedule, setSelectedSchedule,schedulePage } = props;
  let HouseDetails = useSelector(state => state.HouseDetails)
  const schedule = useSelector((state)=>state.schedule)
  const User = useSelector(state => state.User)
  let [selectTime, setSelectedTime] = useState(new Date())
  let [selectOffTime, setSelectedOffTime] = useState(new Date())
  let [switchData, setSwitchData] = useState([])
  let [newStateSchedules,setNewStateSchedules] = useState([])
  let [stateNew, setStateNew] = useState([])
  let [btn, setBtn] = useState("Submit")
  let [repeat, setRepat] = useState("")
  let [name, setName] = useState("")
  let dispatch = useDispatch()
  let [days, setDays] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
  })
  useEffect(() => {
    if (Object.keys(selectedSchedule).length === 0) return

    setName(selectedSchedule.name)
    setRepat(selectedSchedule.repeat)
    setSelectedTime(moment(selectedSchedule.time))
    
    let index = schedule[0].schedule.findIndex(s=>(s.name===selectedSchedule.name && s._id === selectedSchedule.scheduleId ))
    
    setSelectedOffTime(moment(schedule[0].schedule[index+1]?.time))
    
    setStateNew(selectedSchedule.states)
    let newDays = days
    for (let d of selectedSchedule.day) {
      newDays = { ...newDays, [d]: true }
    }
    setDays(newDays)
    let newStateSchedule = []
    for(let i of schedule){
  
      for(let j of i.schedule){
      
        if(j.name===selectedSchedule.name && j._id === selectedSchedule.scheduleId ){
         
          
              
              for(let s of j.states){
                if(s.switchState==="0") continue;
               let data = findHoseDetails(s.deviceId,s.switchId,s.roomId)
               newStateSchedule.push({...s,switchName:data[0],roomName:data[1],propertyName:data[2]})
              
          
          
        }
      }}
    }
    setNewStateSchedules(newStateSchedule)
  
  }, [selectedSchedule])

  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const handleTimeChange = (selectedTime) => {
    // Do something with the selected time
    setSelectedTime(selectedTime)
  };
  const handleTimeOffChange = (selectOffTime) => {
    // Do something with the selected time
    setSelectedOffTime(selectOffTime)
  };
  const handleSubmit = async () => {
    if (name === "") {
      notifyFailed("Plz Enter name");
      return
    }
    if (selectTime === "") {
      notifyFailed("Reselect Time")
      return
    }
    if (repeat === "") {
      notifyFailed("Plz Select Repeat");
      return
    }
    let day = []
    Object.keys(days).map((d, index) => {
      if (days[index] === true) {
        day.push(index)
      }
    })
    if (day.length === 0) {
      notifyFailed("Select Days");
      return
    }
    let newStateArray = []
    for (let s of newStateSchedules) {

      let obj = {
        "type": s.type,
        "deviceType": s.deviceType,
        "switchId": s.switchId,
        "deviceId": s.deviceId,
        "switchState": "1",
    
        "roomId": s.roomId
      }
      newStateArray.push(obj)
    }

    let data = {
      house: "64ba41ca74eca9173f693cbc",
      name: name,
      time: new Date(selectTime),
      offtime: new Date(selectOffTime),
      day: day,
      userName: User.userName,
      scene: undefined,
      states: newStateArray,
      repeat: repeat,
      offset: new Date().getTimezoneOffset(),
    }
    setBtn("Saving...")
    let res = await editSchedule(selectedSchedule.mainId, data, new Date(selectOffTime))
    dispatch(emptySchedule([]))
    if (res.success === true) {
      fetchSingleSchedule()
      props.onHide()
      setBtn("Submit")
      setRepat("")
      setName("")
      setSelectedTime("")
      setSelectedOffTime("")
      setDays({
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
      })
    } else {
      setBtn("Submit")
    }
  }
  const changeState = (event, deviceId, switchId) => {
    let list = selectedSchedule
    let arry = []
    for (let s of stateNew) {
      if (s.deviceId === deviceId && s.switchId === switchId) {
        arry.push({ ...s, switchState: "1" })
      } else {
        arry.push(s)
      }
    }

    setStateNew(arry)
  }
  const removeSwitch = (switchId,deviceId) => {
    let newSwitch = []
  
    for(let i of newStateSchedules){
      
      if(Number(i.switchId) !== Number(switchId) || String(i.deviceId) !== String(deviceId)){
        newSwitch.push(i)
      }
    }
    
    
    setNewStateSchedules(newSwitch)
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >

      <Modal.Body>
        <h6>Schedule Name</h6>
        <InputGroup size="sm" className="mb-3">

          <Form.Control
            aria-label="Small"
            aria-describedby="inputGroup-sizing-sm"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </InputGroup>
        <div style={{ display: "flex", gap: "15px" }}>
          <div>
            <h6>Set ON Time</h6>
            <div style={{ width: "20%" }}>
              <DateTime
                onChange={handleTimeChange}
                dateFormat={false}
                input={false}
                closeOnClickOutside={true}
                utc={false}
                timeFormat={true}
                value={selectTime}
              />
            </div>
          </div>
          <div>
            <h6>Set OFF Time</h6>
            <div style={{ width: "20%" }}>
              <DateTime
                onChange={handleTimeOffChange}
                dateFormat={false}
                input={false}
                closeOnClickOutside={true}
                utc={false}
                timeFormat={true}
                value={selectOffTime}
              />
            </div>
          </div>
        </div>
        <h6 style={{ marginTop: "0.5rem" }}>Set Day</h6>
        <div style={{ border: "1px solid #DBDBDB", borderRadius: "12px", padding: "1rem" }}>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Form.Check type="radio" aria-label="radio 1" label="Repeat Once" name="radio" onClick={() => setRepat("once")} checked={repeat === "once" ? "checked" : ""} />
            <Form.Check type="radio" aria-label="radio 2" label="Repeat Every" name="radio" onClick={() => setRepat("every")} checked={repeat === "every" ? "checked" : ""} />
          </div>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingTop: "1rem" }}>
            {
              daysOfWeek && (
                daysOfWeek.map((day, index) => {

                  return (
                    <Button
                      size='sm'
                      variant={days[index] === false ? "outline-primary" : "primary"}
                      key={index}
                      onClick={() => setDays({ ...days, [index]: !days[index] })}
                    >
                      {day}
                    </Button>
                  )
                })
              )
            }
          </div>
        </div>
        <h6 style={{ marginTop: "0.5rem" }}>Set Appliance State</h6>
        <Table>
          <thead>
            <tr>
              <th>#</th>
              <th>Property</th>
              <th>Area</th>
              <th>Appliance</th>
              {/* <th>Sechedule to</th> */}
            </tr>
          </thead>
          <tbody>
            {
              newStateSchedules && (
                newStateSchedules.map((swit, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{swit.propertyName}</td>
                      <td>{swit.roomName}</td>
                      <td>{swit.switchName}</td>
                      {/* <td>
                        <Stack direction="horizontal" gap={3}>
                          <Button
                            variant={swit.switchState != "0" ? "primary" : "outline-secondary"}
                          
                            size='sm'
                            style={{
                              display: "flex",
                              alignItems: "center"
                            }}
                            onClick={()=>changeState("1",swit.deviceId,swit.switchId)}
                          >
                            <SlashCircle style={{ marginRight: "5px" }} />
                            Turn On
                          </Button>
                          <Button
                            variant={swit.switchState == "0" ? "primary" : "outline-secondary"}
                            size='sm'
                            style={{
                              display: "flex",
                              alignItems: "center"
                            }}
                            onClick={()=>changeState("0",swit.deviceId,swit.switchId)}
                          >
                            <SlashCircle style={{ marginRight: "5px" }} />
                            Turn Off
                          </Button>
                        </Stack>
                      </td> */}
                      <td><Trash onClick={() => removeSwitch(swit.switchId,swit.deviceId)} style={{ cursor: "pointer" }} /></td>
                    </tr>
                  )
                })
              )
            }


          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => handleSubmit()}
          style={{ pointerEvents: btn === "Submit" ? "" : "none" }}
          variant={btn === "Submit" ? "primary" : "secondary"}
        >{btn}</Button>
      </Modal.Footer>
    </Modal>
  );
}


