import { useState } from 'react';
import { SlashCircle } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import {serverUrl} from '../../ApiUri'
import axios from 'axios';
import { deleteAllSchedule, emptySchedule, removeSchedule } from '../Redux/Action/ScheduleAction';
import { notifySuccess } from '../utlity/Notification';

function ConfirmationModel({}) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    let [btn,setBtn] = useState("Yes")
    const schedule = useSelector(state=>state.schedule)
    const User = useSelector(state=>state.User)
    const dispatch = useDispatch()

    const deleteSchedules = async()=>{
        setBtn("Loading...")
        // for(let s of schedule){
        //     for(let i of s.schedule){
        //         await axios.delete(
        //             `${serverUrl.main}/v2/schedule/${i._id}?user=${
        //               User.userName
        //             }&time=${new Date().getTime()}`,
        //           )
        //             .then(function (res) {
        //               console.log('====================================');
        //               console.log(res,"res delete");
        //               console.log('====================================');
        //                  if(res.data.success===true){
        //                     dispatch(removeSchedule(i._id))
        //                  }else{
        //                  }
        //             })
        //             .catch(error => {
        //               console.log(error,"delete");
        //             });
        //     }
        // }
        deleteAllSchedule()
        notifySuccess("All Schedules Deleted")
        setBtn("Done")
        dispatch(emptySchedule([]))
        handleClose()
    }
  return (
    <>
    <Button
            variant="outline-secondary"
            size='sm'
            style={{
              display: "flex",
              alignItems: "center"
            }}
           onClick={handleShow}
          >
            <SlashCircle style={{ marginRight: "5px" }} />
            Delete All Schedules
          </Button>

    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to Delete All Schedules ?.</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" style={{pointerEvents:btn==="Loading..."?"none":""}} onClick={deleteSchedules}>
          {btn}
        </Button>
      </Modal.Footer>
    </Modal>
  </>
  );
}

export default ConfirmationModel;