import { deviceOnTime } from "../Action/ActionTypes"



export const switchOnTimeData = (state={},action)=>{
   const {type,payload} = action
   switch (type) {
    case deviceOnTime.LOAD:
        let newState = {...state}
        if(newState[payload.deviceId]===undefined){
            newState[payload.deviceId]={}
        }
        if(newState[payload.deviceId][payload.date]===undefined){
            newState[payload.deviceId][payload.date]={}
        }
        newState[payload.deviceId][payload.date]=payload.data
       return newState
    case deviceOnTime.EMPTY:
        return {}
    default:
        return state
   }
}