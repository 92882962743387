import axios from "axios"
import { schedule } from "./ActionTypes"
import { serverUrl } from "../../../ApiUri"
import store from "../index"
import { notifyFailed, notifySuccess } from "../../utlity/Notification"


export const scheduleFetch = (data) => {
  return {
    type: schedule.FETCH_SCHEDULE,
    payload: data
  }

}

export const scheduleAdd = (data) => {
  return {
    type: schedule.ADD_SINGLE_SCHEDULE,
    payload: data
  }
}

export const emptySchedule = (data) => {
  return {
    type: schedule.EMPTY_SCHEDULE,
    payload: []
  }
}

export const removeSchedule = (data) => {
  return {
    type: schedule.REMOVE_SCHEDULE,
    payload: data
  }
}
export const removeMultpleSchedule = (data)=>{
  return {
    type: schedule.REMOVE_MULTPLESCHEDULE,
    payload: data
  }
}


export const AddSchedule = async (details) => {

  await axios.post(
    `${serverUrl.main}/app/stanza/addSchedule`,
    details,
  ).then(function (res) {

    if (res.data.success === true) {
      notifySuccess(res.data.message)
    } else {
      notifyFailed(res.data.message)
    }

  }).catch(function (err) {
    console.log(err);

  })

}

export const fetchSchedule = async () => {

  let SchedulesData = []
  for (let house of store.getState().HouseDetails) {

    if (house.success === true) {
     await fetchSingleSchedule(house.house._id)
    }
  }

}
export const fetchSingleSchedule = async (houseid="64ba41ca74eca9173f693cbc") => {
  let response = await axios.post(
    `${serverUrl.main}/app/stanza/fetchSchedules`, {
    houseId: houseid
  }
  ).then(function (res) {
    if (res.data.success === true) {
      if (res.data.data !== undefined) {
        store.dispatch(scheduleAdd({ success: true, schedule: res.data.data,id:houseid  }))
      }else{
        store.dispatch(scheduleAdd({ success: false, schedule: [],id:houseid  }))
      }
    } else {
      notifyFailed(res.data.message)
      store.dispatch(scheduleAdd({ success: false, schedule: [],id:houseid  }))
    }
  }).catch(function (err) {
    console.log(err);
    store.dispatch(scheduleAdd({ success: false, schedule: [],id:houseid  }))
  })
  return response;
}

export const editSchedule = async (scheduleId, details, offtime) => {

  return await axios.post(
    `${serverUrl.main}/app/stanza/editSchedule`, {
    details,
    id: scheduleId,
    user: store.getState().User.userName,
    offtime,
    userName: store.getState().User.userName
  },
  ).then(function (res) {

    if (res.data.success === true) {
      if (res.data.data !== undefined && res.data.data.success === true) {
        notifySuccess(res.data.data.messagae)
      }
      if (res.data.data !== undefined && res.data.data.success === false) {
        notifyFailed(res.data.data.messagae)
      }
      notifySuccess(res.data.messagae)
      
    } else {
      notifyFailed(res.data.messagae)
    }
    return res.data
  }).catch(function (err) {
    console.log(err);
    return {
      success: false,
      message: "Failed"
    }
  })
  
}

export const deleteAllSchedule = async () => {

  
  const map = new Map();
  const data = [];

  // Store objects from array1 in a map based on the id field
  store.getState().HouseDetails.forEach(obj => map.set(obj.id, obj));
  
  // Iterate over array2, check if the object exists in the map, if yes, push to result
  store.getState().schedule.forEach(obj => {
      obj.schedule.forEach(sc =>{
     
        sc.states.forEach(scc=>{
          if (map.has(scc.houseId)) {
            data.push(sc);
        }
        })
    
      })
     
  });

  // for (let s of store.getState().schedule) {
  //   for (let i of s.schedule) {
      
      
  //       data.push(i)
      
  //   }
  // }
  let promise = []
  let scheduleRemove = []
  var count = 0
  for (let d of data) {
  
    scheduleRemove.push(d.mainId)
     promise.push(deleteSingleSchedule(d,count))
  }
   await Promise.all(promise)
  store.dispatch(removeMultpleSchedule(scheduleRemove))
  fetchSingleSchedule()
  notifySuccess(`${data.length} Schedules Deleted`)
  
}

export const deleteSchedule = async (schedule) => {

  
  let data = [];
  for (let s of store.getState().schedule) {
    for (let i of s.schedule) {
      
      if (i.mainId === schedule.mainId) {
        data.push(i)
      }
    }
  }
  let promise = []
  let scheduleRemove = []
  var count = 0
  for (let d of data) {
    scheduleRemove.push(d.mainId)
     promise.push(deleteSingleSchedule(d,count))
  }
   await Promise.all(promise)
  store.dispatch(removeMultpleSchedule(scheduleRemove))
  fetchSingleSchedule()
  notifySuccess(`${data.length} Schedules Deleted`)
  
}

const deleteSingleSchedule = async (d,count) => {
  await axios.delete(`${serverUrl.main}/app/stanza/deleteSchedule`, {
    data: {
      "id": d.mainId,
      "user": store.getState().User.userName,
    }
  },).then(function (res) {
    if (res.data.success === true) {
      count=count+1
      // store.dispatch(removeSchedule(d.mainId))
      if (res.data.data !== undefined && res.data.data.success === false) {
      }
    
    } else {

    }
  })
    .catch(error => {

    });
}