export async function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function convertMillisecondsToTimeObject(ms) {
  // Calculate hours
  const hours = Math.floor(ms / (1000 * 60 * 60));
  ms %= 1000 * 60 * 60;

  // Calculate minutes
  const minutes = Math.floor(ms / (1000 * 60));
  ms %= 1000 * 60;

  // Calculate seconds
  const seconds = Math.floor(ms / 1000);

  return {
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };
}


export function convertMillisecondsToString(ms) {
  let seconds = ms / 1000;
  let hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  let minutes = Math.floor(seconds / 60);
  let result = `${hours.toString().padStart(2, "0")}h ${minutes
    .toString()
    .padStart(2, "0")}m`;
  return result;
}