import { useEffect, useState } from "react";
import {
  deleteSchedule,
  deleteSchedulesByTag,
  getSchedule,
} from "../../utlity/apis";
import { Trash3Fill } from "react-bootstrap-icons";
import { notifyFailed, notifySuccess } from "../../utlity/Notification";
import { useDispatch, useSelector } from "react-redux";
import {
  addSchedule,
  deleteSchedule as deleteScheduleRedux,
  updateSchedule as updatedScheduleRedux,
} from "../../Redux/Action/NewSchedule";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function NewFetchScheduleRow({ houseId, deviceId }) {
  
  const [schedules, setSchedules] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);

  function handleClose() {
    setShowConfirmation(false);
    setTag('');
  }

  const [tag, setTag] = useState('');

  const dispatch = useDispatch();
  const schedule = useSelector((state) => state.newSchedule);

  // console.log("SCHEDULES - ", schedule)
  // useEffect(() => {
  //   let isMounted = true;

  //   const fetchData = async () => {
  //     try {
  //       const resp = await getSchedule({ houseId });
  //       if (isMounted) {
  //         const schdls = resp?.data?.data?.schedules;
  //         let data = [];

  //         // No response
  //         if (!schdls) return;

  //         for (const s of schdls) {
  //           for (const action of s?.actions) {
  //             if (action?.payload?.deviceId === deviceId) {
  //               setSchedules((state) => {
  //                 if (state.some((schdl) => schdl._id === s._id)) {
  //                   return state;
  //                 }
  //                 return [...state, s];
  //               });
  //             }
  //           }
  //           dispatch(addSchedule(s));
  //           // if (s?.actions?.[0]?.payload?.deviceId === deviceId) {
  //           //   data.push(s);
  //           // }
  //         }
  //         // setSchedules(data);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching schedule:", error);
  //     }
  //   };

  //   fetchData();

  //   return () => {
  //     isMounted = false;
  //   };
  // }, [houseId, deviceId, schedule]);

  useEffect(() => {
    const data = [];
    for (const s of schedule) {
      for (const action of s?.actions) {
        if (action?.payload?.deviceId === deviceId) {
          data.push(s);
          // setSchedules((state) => {

          //   // if (state.some((schdl) => schdl._id === s._id)) {
          //   //   console.log("---")
          //   //   return state;
          //   // }
          //   // console.log("S - ", s);
          //   // return [...state, s];
          // });
        }
      }
      // if (s?.actions?.[0]?.payload?.deviceId === deviceId) {
      //   data.push(s);
      // }
    }
    setSchedules(data);
  }, [schedule]);

// useEffect(() => {
  //   // console.log(s)
  //   for (const schdl of schedule) {
  //     // console.log("T - ", schdl?.actions?.[0]?.payload?.deviceId);
  //     // console.log("X - ", schdl?.actions, deviceId)
  //     if (schdl.house === houseId) {
  //       console.log("YESS");

  //       // setSchedules((state) => [...state, schdl]);
  //     }
  //   }
  // }, [schedule]);

  // function removeSchedule(id) {
  //   deleteSchedule({ scheduleId: id }).then((res) => {
  //     if (res.success) {
  //       dispatch(deleteScheduleRedux(id));
  //       setSchedules((state) => {
  //         return state.filter((schdl) => schdl._id !== id);
  //       });
  //       notifySuccess("Schedule Deleted!!");
  //     }
  //   });
  // }

  function showModal(tag) {
    setShowConfirmation(true);
  }
  function removeScheduleByTag(tag) {
    if (tag === '') {
      notifyFailed('Tag is empty')
      return
    }
    deleteSchedulesByTag({ tag }).then((res) => {
      if (res.success) {
        // Remove schedules from latest state
        const updatedSchedules = schedule.filter((schdl) =>
          schdl.tags ? !schdl?.tags?.includes(tag) : true
        );

        dispatch(updatedScheduleRedux(updatedSchedules));

        // Update State
        // setSchedules((state) =>
        //   state.filter((schdl) => schdl.tags ?  !schdl?.tags?.includes(tag) : true)
        // );
      }
    });
  }
  return (
    <>
      <ul style={{ textAlign: "left" }}>
        {schedules.length !== 0 ? (
          schedules.map((s, i) => (
            <div style={{ display: "flex", gap: 15 }} key={i}>
              <li style={{ cursor: "pointer", color: "#1E1EBB" }}>
                {s.name} - {cronToTimeString(s.expression)}
              </li>
              <Trash3Fill
                onClick={() => {
                  // removeScheduleByTag(s?.tags[0]);
                  showModal();
                  setTag(s?.tags[0])
                }}
              />
            </div>
          ))
        ) : (
          <div>No Schedule Set</div>
        )}
      </ul>

      {/* modal */}

      <Modal show={showConfirmation} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Multiple Schedules</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete multiple Schedules.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={() => {
            removeScheduleByTag(tag);
            handleClose();
            setTag('');
          }}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function cronToTimeString(cronExpression) {
  const fields = cronExpression.split(" ");

  if (fields.length < 5) {
    throw new Error("Invalid cron expression");
  }

  const minutes = fields[0];
  let hours = fields[1];

  let { hour, type } = convertTo12HourFormat(hours);
  return `${String(hour).padStart(2, "0")}:${minutes.padStart(2, "0")} ${type}`;
}

function convertTo12HourFormat(hour) {
  hour = Number(hour);
  if (hour === 0) {
    return { hour: 12, type: "AM" };
  } else if (hour === 12) {
    return { hour: 12, type: "PM" };
  } else if (hour > 12) {
    return { hour: hour - 12, type: "PM" };
  } else {
    return { hour: hour, type: "AM" };
  }
}
