import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import DateTime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import "../ScheduleModal.css";
import { Form, InputGroup, Stack, Table } from "react-bootstrap";
import { SlashCircle, Trash } from "react-bootstrap-icons";
import { useEffect, useState } from "react";
import { switchState } from "../../Redux/Action/ActionTypes";
import { useDispatch, useSelector } from "react-redux";
import {
  AddSchedule,
  fetchSchedule,
  fetchSingleSchedule,
} from "../../Redux/Action/ScheduleAction";
import moment from "moment";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { notifyFailed, notifySuccess } from "../../utlity/Notification";
import { setSchedule } from "../../utlity/apis";
import { addSchedule } from "../../Redux/Action/NewSchedule";

export default function NewScheduleModal(props) {
  const { productDataItem } = props;
  const User = useSelector((state) => state.User);
  let HouseDetails = useSelector((state) => state.HouseDetails);
  let [selectTime, setSelectedTime] = useState(moment(new Date()).format('HH:mm'));
  let [selectOffTime, setSelectedOffTime] = useState(moment(new Date()).format('HH:mm'));
  let [switchData, setSwitchData] = useState([]);
  let [repeat, setRepat] = useState("");
  let [name, setName] = useState("");
  let [btn, setBtn] = useState("Submit");

  const dispatch = useDispatch();

  const newSchedule = useSelector(state => state.newSchedule)

  
  useEffect(() => {
    setSelectedOffTime(moment(new Date()));
    setSelectedTime(moment(new Date()));
  }, []);
  useEffect(() => {
    setSelectedTime(moment(new Date()));
    setSelectedOffTime(moment(new Date()));
    let switchs = [];
    for (let swit of productDataItem) {
      let obj = {
        deviceId: swit.deviceId,
        deviceType: swit.deviceType,
        roomId: swit.roomId,
        switchId: swit.switchId,
        switchState: "1",
        type: swit.type,
        switchName: swit.switchName,
        roomName: swit.roomName,
        _id: swit._id,
        houseId: swit.houseId,
        houseName: swit.houseName,
      };
      switchs.push(obj);
      setSwitchData(switchs);
    }
  }, [productDataItem]);


  let [days, setDays] = useState({
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
    6: true,
    7: true,
  });

  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const handleTimeChange = (selectedTime) => {
    // Do something with the selected time
    setSelectedTime(selectedTime);
  };
  const handleTimeOffChange = (selectOffTime) => {
    // Do something with the selected time
    setSelectedOffTime(selectOffTime);
  };

  const handleSubmit = async () => {
    const tagTimeStamp = new Date().getTime();

    if (name === "") {
      notifyFailed("Plz Enter name");
      return;
    }
    if (selectTime === "") {
      notifyFailed("Reselect Time");
      return;
    }

    let day = [];
    Object.keys(days).map(async (d, index) => {
      if (days[index+1] === true) {
        day.push(index+1);
      }
    });

    if (day.length === 0) {
      notifyFailed("Select Days");
      return;
    }

    const onTimeCronExpression = createCronExpression(
      selectTime.format("HH:mm"),
      day
    );

    const offTimeCronExpression = createCronExpression(
      selectOffTime.format("HH:mm"),
      day
    );

   
    
    // Create payload
    props.onHide();

    let onSchedules = [];
    let offSchedules = [];

    const houseData = {};
    for (const house of productDataItem) {
      
      if (!houseData[house.houseId]) {
        houseData[house.houseId] = [];
      }

      houseData[house.houseId] = [...houseData[house.houseId], house.deviceId];

    }

    for (const house of Object.keys(houseData)) {

      let onObj = {
        name: `${name} on`,
        house: house,
        type: "cron",
        expression: onTimeCronExpression,
        enabled: true,
        actions: [],
        tags: [tagTimeStamp],
      };

      let offObj = {
        name: `${name} off`,
        house: house,
        type: "cron",
        expression: offTimeCronExpression,
        enabled: true,
        actions: [],
        tags: [tagTimeStamp],
      };

      // Adding multiple devices to obj
      for (const device of houseData[house]) {
        onObj = {
          ...onObj,
          actions: [
            ...onObj.actions,
            {
              action: "Sync/SetSwitchPins",
              payload: {
                deviceId: device,
                states: "1",
              },
            },
          ],
        };

        offObj = {
          ...offObj,
          actions: [
            ...offObj.actions,
            {
              action: "Sync/SetSwitchPins",
              payload: {
                deviceId: device,
                states: "0",
              },
            },
          ],
        };

      }


        onSchedules.push(onObj);
        offSchedules.push(offObj);
    }
    
        setBtn("Saving...");
        let promises = [];
        for (let v of [...onSchedules, ...offSchedules]) {
          promises.push(setSchedule(v));
        }
        props.onHide();
        setBtn("Submit");
        setName("");
        setSelectedTime("");
        setDays({
          1: true,
          2: true,
          3: true,
          4: true,
          5: true,
          6: true,
          7: true,
        });
        const resp = await Promise.all(promises);

        for (const res of resp) {
          if (res.data.success) {
            dispatch(addSchedule(res?.data?.data?.schedule));
            notifySuccess("Schedule Created Successfully");
          }
        }
  };
  const turnOnOff = (checked) => {
    let newData = switchData.map((s, index) => {
      if (checked === true) {
        return { ...s, switchState: "1" };
      } else if (checked === false) {
        return { ...s, switchState: "0" };
      }
    });
    setSwitchData(newData);
  };
  const removeSwitch = (id) => {
    let newSwitch = switchData.filter((e) => e._id !== id);
    setSwitchData(newSwitch);
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <h6>Schedule Name</h6>
        <InputGroup size="sm" className="mb-3">
          <Form.Control
            aria-label="Small"
            aria-describedby="inputGroup-sizing-sm"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </InputGroup>
        <div style={{ display: "flex", gap: "15px" }}>
          <div>
            <h6>Set ON Time</h6>
            <div style={{ width: "20%" }}>
              <DateTime
                onChange={handleTimeChange}
                dateFormat={false}
                input={false}
                closeOnClickOutside={true}
                utc={false}
                timeFormat={true}
                value={selectTime}
              />
            </div>
          </div>
          <div>
            <h6>Set OFF Time</h6>
            <div style={{ width: "20%" }}>
              <DateTime
                onChange={handleTimeOffChange}
                dateFormat={false}
                input={false}
                closeOnClickOutside={true}
                utc={false}
                timeFormat={true}
                value={selectOffTime}
              />
            </div>
          </div>
        </div>
        <h6 style={{ marginTop: "0.5rem" }}>Set Day</h6>
        <div
          style={{
            border: "1px solid #DBDBDB",
            borderRadius: "12px",
            padding: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {daysOfWeek &&
              daysOfWeek.map((day, index) => {
                return (
                  <Button
                    size="sm"
                    variant={
                      days[index+1] === false ? "outline-primary" : "primary"
                    }
                    key={index+1}
                    onClick={() => setDays({ ...days, [index+1]: !days[index+1] })}
                  >
                    {day}
                  </Button>
                );
              })}
          </div>
        </div>
        <h6 style={{ marginTop: "0.5rem" }}>Set Appliance State</h6>

        <Table>
          <thead>
            <tr>
              <th>#</th>
              <th>Property</th>
              <th>Area</th>
              <th>Appliance</th>
            </tr>
          </thead>
          <tbody>
            {switchData &&
              switchData.map((swit, i) => {
                return (
                  <tr key={i + "switch"}>
                    <td>1</td>
                    <td>{swit.houseName}</td>
                    <td>{swit.roomName}</td>
                    <td>{swit.switchName}</td>
                    <td>
                      <Trash
                        onClick={() => removeSwitch(swit._id)}
                        style={{ cursor: "pointer" }}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => handleSubmit()}
          style={{ pointerEvents: btn === "Submit" ? "" : "none" }}
          variant={btn === "Submit" ? "primary" : "secondary"}
        >
          {btn}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}


function createCronExpression(timeStr, weekdays) {
    const [hour, minute] = timeStr.split(':').map(Number);

    if (hour < 0 || hour > 23 || minute < 0 || minute > 59) {
        throw new Error("Invalid time format");
    }

    // for (const day of weekdays) {
    //     if (day < 0 || day > 6) {
    //         throw new Error("Invalid weekday");
    //     }
    // }

    const cronWeekdays = weekdays.join(',');

    // Cron format: minute hour * * day(s)
    const cronExpression = `${minute} ${hour} ? * ${cronWeekdays} *`;
    return cronExpression;
}

