import React from 'react'

export default function SwitchLiveStatus({switchStatus="not"}) {
  return (
    <>
    {
      switchStatus === "live" ? (
          <span style={{ color: "#198754", fontSize: "14px" }}>Live</span>
        ) : switchStatus === "not" ? (
          <span style={{ color: "#dc3545", fontSize: "14px" }}>Not Live</span>
        ) : (
          <span style={{ color: "#dc3545", fontSize: "14px" }}>Offline</span>
        )
    }
    </>
  )
}
