import { newSchedule } from "./ActionTypes"


export const addSchedule = (data) => {
    return {
        type: newSchedule.ADD_SCHEDULE,
        payload: data
    }
}

export const deleteSchedule = (data) => {
    return {
        type: newSchedule.REMOVE_SCHEDULE,
        payload: data
    }
}

export const updateSchedule = (data) => {
    return {
        type: newSchedule.UPDATE_SCHEDULE,
        payload: data
    }
}
