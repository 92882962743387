import axios from "axios";
import { serverUrl } from "../../ApiUri";

export const getAnalysisData = async (dSIDs,date) => {
    try {
      
    

    const res = await axios.post(
      `${serverUrl.deviceLog}/legacy/dayAnalytics`,

      {
        deviceId: dSIDs,
        day: date,
        // firstDate:
        //   startDate === "" && endDate === ""
        //     ? new Date(new Date().setDate(new Date().getDate() - 3))
        //     : new Date(startDate),
        // lastDate:
        //   endDate === "" && startDate === ""
        //     ? new Date(new Date().setDate(new Date().getDate() - 1))
        //     : new Date(endDate),
      }
    );

    return res;
        // if (res.data.result === "success") {
        //   return {
        //     success: true, 
        //     data: res.data.data[0].data
        // }
        // } else {
        //   return {
        //     success: false,
        //     data: {}
        //   }
        // }
      
    } catch(error) {
        return {
            success: false,
            data: {}
        }        
      };
}

export const setSchedule = async (data) => {
  try {
    const res = await axios.post(
      "https://web.alistetechnologies.com/v3/centralschedules/create",
      data
    );


    return {
      success: true,
      data: res.data
    }
  } catch (error) {
    return {
      success: false,
      data: {}
    }
  }
}

export const getSchedule = async (data) => {
  try {
    const res = await axios.post(
      "https://web.alistetechnologies.com/v3/centralschedules/listByHouse",
      data
    );

    if (!res.data.success) {
      return {
        success: false,
        data: res.data
      }
    }
    return {
      success: true,
      data: res.data
    }
  } catch (error) {
    return {
      success: false,
      data: {}
    } 
  }
}


export const deleteSchedule = async (data) => {
  try {
    const res = await axios.post(
      `https://web.alistetechnologies.com/v3/centralschedules/remove`, data
    );

    if (!res.data.success) {
      return {
        success: false,
        data: {},
        error: null
      }
    }

    return {
      success: true,
      data: res.data,
      error: null
    }
  } catch (error) {
    return {
      success: false,
      data: {},
      error: error
    }
  }
}


export const deleteSchedulesByTag = async (data) => {
  try {
    const res = await axios.post(
      "https://web.alistetechnologies.com/v3/centralschedules/deleteByTag",
      data
    )

    if (!res.data.success) {
      return {
        success: false,
        data: {},
        error: null
      }
    }

    return {
      success: true,
      data: res.data,
      error: null
    }
  } catch (error) {
    return {
      success: false,
      data: {},
      error: error
    }
  }
}

export const listSchedulesByTag = async (data) => {
  try {
    const res = await axios.post(
      "https://web.alistetechnologies.com/v3/centralschedules/listByTag",
      data
    );

    if (res.data.success) {
      return {
        success: false,
        data: {},
        error: null,
      };
    }

    return {
      success: true,
      data: res.data,
      error: null,
    };
  } catch (error) {
    return {
      success: false,
      data: {},
      error: error,
    };
  }
}

export const getMacids = async (data) => {
  try {
    const res = await axios.post(
      "https://int.alistetechnologies.com/deviceIdAssign/macs",
      data,
      {
        headers: {
          AccessToken:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MmZiNTU1NjhkYWZlNTJmMjcwMDAwNWYiLCJlbXBsb3llZUlkIjoiQTA0IiwidGltZSI6IjIwMjMtMDMtMThUMDc6MTQ6NDEuMzE0WiIsImlhdCI6MTY3OTEyMzY4MX0.QYP5QNT1fkStrsmxdGr_fiq_TDisdePS8i1CzO1n46c",
        },
      }
    );

    return res.data;

  } catch (error) {
    return {
      success: false,
      data: {},
      error
    }
  }
}