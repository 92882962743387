import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
const msToHoursMinutes = (milliseconds) => {
  let seconds = milliseconds / 1000;
  let hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  let minutes = Math.floor(seconds / 60);
  let result = `${hours.toString().padStart(2, "0")}h ${minutes
    .toString()
    .padStart(2, "0")}m`;
  return result;
};

export default function LogModalOnTimeTr({ date, logSwitch }) { 

  const switchOnTime = useSelector((state) => state.switchOnTime);
  const idData = switchOnTime[`${logSwitch.deviceId}$${logSwitch.switchId}`];
  let deviceLogObj = { sessions: [] };
  if (idData && idData[date]) {
    deviceLogObj = idData[date];
  }

  const sessions =
    deviceLogObj.sessions !== undefined ? deviceLogObj.sessions : [];
  return (
    <>
      {sessions.length === 0 ? (
        <tr>
          <td>{moment(date).format("DD-MMM-YYYY")}</td>
          <>
            <td>-</td>
            <td>-</td>
            <td>-</td>
          </>

          <td>
            {switchOnTime[`${logSwitch.deviceId}$${logSwitch.switchId}`][date]
              ? msToHoursMinutes(deviceLogObj.onlineTime)
              : 0}
          </td>
          <td>
            {switchOnTime[`${logSwitch.deviceId}$${logSwitch.switchId}`][date]
              ? msToHoursMinutes(deviceLogObj.ontime)
              : 0}
          </td>
        </tr>
      ) : (
        sessions.map((session) => (
          <tr>
            <td>{moment(date).format("DD-MMM-YYYY")}</td>
            <>
              <td>{moment(session.startTime).format("hh:mm A")}</td>
              <td>{moment(session.endTime).format("hh:mm A")}</td>
              <td>{msToHoursMinutes(session.endTime - session.startTime)}</td>
            </>
            <td>
              {switchOnTime[`${logSwitch.deviceId}$${logSwitch.switchId}`][date]
                ? msToHoursMinutes(deviceLogObj.onlineTime)
                : 0}
            </td>
            <td>
              {switchOnTime[`${logSwitch.deviceId}$${logSwitch.switchId}`][date]
                ? msToHoursMinutes(deviceLogObj.ontime)
                : 0}
            </td>
          </tr>
        ))
      )}
    </>
  );
}
